import { IIndexable } from '@/customtypes/common';

import bnbIcon from '@/assets/images/defi/bnb.svg';
import bnbxDarkIcon from '@/assets/images/defi/bnbx_dark.svg';
import bnbxIcon from '@/assets/images/defi/bnbx_light.svg';
import btcIcon from '@/assets/images/defi/btc.png';
import busd from '@/assets/images/defi/busd.png';
import dai from '@/assets/images/defi/dai.png';
import daiDark from '@/assets/images/defi/dai_dark.png';
import Eth from '@/assets/images/defi/eth.svg';
import ethxIcon from '@/assets/images/defi/ethx.svg';
import ethxDarkIcon from '@/assets/images/defi/ethx_dark.svg';
import fraxIcon from '@/assets/images/defi/frax.png';
import GraiIcon from '@/assets/images/defi/grai.webp';
import maticLight from '@/assets/images/defi/matic.svg';
import maticDark from '@/assets/images/defi/matic.svg';
import maticx from '@/assets/images/defi/maticx.svg';
import maticXDark from '@/assets/images/defi/maticx_dark.svg';
import pol from '@/assets/images/defi/pol.svg';
import saucerswap from '@/assets/images/defi/saucerswap.svg';
import etherLightIcon from '@/assets/images/defi/tether_light.png';
import ThenaIcon from '@/assets/images/defi/thena.png';
import usdc from '@/assets/images/defi/usdc.png';
import usdcDark from '@/assets/images/defi/usdc_dark.png';
import sdIcon from '@/assets/images/sd.svg';

// TODO: add wsteth icon
export const defiCurrencyIconMapping: IIndexable = {
  busd: {
    light: busd,
    dark: busd,
  },
  ethx: {
    light: ethxIcon,
    dark: ethxDarkIcon,
  },
  bnb: {
    light: bnbIcon,
    dark: bnbIcon,
  },
  bnbx: {
    light: bnbxIcon,
    dark: bnbxDarkIcon,
  },
  ether: {
    light: etherLightIcon,
    dark: etherLightIcon,
  },
  usdc: {
    light: usdc,
    dark: usdcDark,
  },
  dai: {
    light: dai,
    dark: daiDark,
  },
  frax: {
    light: fraxIcon,
    dark: fraxIcon,
  },
  thena: {
    light: ThenaIcon,
    dark: ThenaIcon,
  },
  btc: {
    light: btcIcon,
    dark: btcIcon,
  },
  eth: {
    light: Eth,
    dark: Eth,
  },
  matic: {
    light: maticLight,
    dark: maticDark,
  },
  polygon: {
    light: maticLight,
    dark: maticDark,
  },
  pol: {
    light: pol,
    dark: pol,
  },
  maticx: {
    light: maticx,
    dark: maticXDark,
  },
  saucerswap: {
    light: saucerswap,
    dark: saucerswap,
  },
  grai: {
    light: GraiIcon,
    dark: GraiIcon,
  },
  sd: {
    light: sdIcon,
    dark: sdIcon,
  },
};
