import { useMemo, useState } from 'react';

import { Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import {
  TOKENTYPE,
  useAllowanceQuery,
  useTVLQuery,
} from '@stader-labs/web-sdk';
import { usePathname } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';

import { ContractTokenConfig } from '@/config/contracts.config';
import { TXN_STAGE_APPROVAL_STATUS } from '@/constants/stake-page.constant';
import { useChainData } from '@/providers/ChainDataProvider';
import { setApprovalData } from '@/store/slices/stake-page.slice';
import { getCurrentChain } from '@/utils/common';

import {
  NON_APPROVAL_CHAINS_STAKE,
  NON_APPROVAL_CHAINS_UNSTAKE,
  STAKE_CONFIRMATION_CHAINS,
  UNSTAKE_CONFIRMATION_CHAINS,
} from '../../constants/common';
import { EVENTS, TRANSACTION_TYPE } from '../../constants/events';
import { CONNECT_WALLET } from '../../constants/firebase';
import { IIndexable } from '../../types/common';
import { emitEvent } from '../../utils/common';
import { trackEvent } from '../../utils/firebase';
import StakeDiclaimer from '../StakeDisclaimer';
import UnstakeLimitExceedModal from '../UnstakeLimitExceedModal';

interface TicketWalletConnectProps {
  type: 'stake' | 'unstake';
  error?: string;
  walletConnectText?: string;
  switchNetworkText?: string;
  primaryActionText?: string;
  approveActionText?: string;
  secondaryText?: string;
  secondaryLink?: string;
  // primaryActionCallBack?(): void;
  setError: (error: string) => void;
  cmsData: any;
  isDisabled?: boolean;
  unstakeLimits?: any;
  stakeToken?: ContractTokenConfig | null;
}

const TicketWalletConnect = ({
  error,
  type,
  walletConnectText = 'Connect Wallet',
  switchNetworkText = 'Switch Network',
  primaryActionText = type === 'stake' ? 'Stake' : 'Unstake',
  approveActionText = 'Approve',
  secondaryLink,
  secondaryText,
  // primaryActionCallBack,
  setError,
  cmsData,
  isDisabled = false,
  unstakeLimits,
  stakeToken = null,
}: TicketWalletConnectProps) => {
  const pathname = usePathname();
  const { address } = useAccount();
  const dispatch = useDispatch();

  const token = getCurrentChain(pathname);
  const [isVisibleUnstakeConfirmation, setIsVisibleUnstakeConfirmation] =
    useState(false);
  const [isVisibleStakeConfirmation, setIsVisibleStakeConfirmation] = useState<
    'approve' | 'stake' | null
  >(null);
  const { isLoading, isConnected, switchNetwork } = useSelector(
    (state: any) => state.wallet,
  );

  const { openConnectModal } = useConnectModal();

  const {
    stakeAmount,
    unstakeAmount,
    isStaking,
    isUnstaking,
    approveTokenLoading: loadingERC20,
    approveTokenXLoading: loadingXToken,
    // tokenAllowence,
    // tokenXAllowence,

    // ! I don't think these are being used
    tokenAssociated,
    tokenXAssociated,
  } = useSelector((state: any) => state.stake);

  const { data: tokenAllowance } = useAllowanceQuery({
    address,
    tokenType: TOKENTYPE.TOKEN,
    stakeToken: stakeToken?.symbol,
    enabled: type === 'stake',
  });

  const { data: tokenXAllowance } = useAllowanceQuery({
    address,
    tokenType: TOKENTYPE.TOKENX,
    stakeToken: stakeToken?.symbol,
    enabled: type === 'unstake',
  });

  const errorMessages: IIndexable = {
    stakeDenied: cmsData?.stake_approve_reject_message,
    unstakeDenied: cmsData?.unstake_approve_reject_message,
  };

  const handleERC20Approve = () => {
    emitEvent(EVENTS.HANDLE_TRANSACTION, {
      type: TRANSACTION_TYPE.APPROVE_TOKEN,
      errorMessages: errorMessages,
    });
  };

  const handleXTokenApprove = () => {
    emitEvent(EVENTS.HANDLE_TRANSACTION, {
      type: TRANSACTION_TYPE.APPROVE_TOKEN_X,
      errorMessages: errorMessages,
    });
  };

  const handleStake = () => {
    emitEvent(EVENTS.HANDLE_TRANSACTION, {
      type: TRANSACTION_TYPE.STAKE,
      amount: stakeAmount,
      errorMessages: errorMessages,
    });
  };

  const handleAssociateToken = () => {
    emitEvent(EVENTS.HANDLE_TRANSACTION, {
      type: TRANSACTION_TYPE.ASSOCIATE_TOKEN_X,
      errorMessages: errorMessages,
    });
  };

  const handleUnstake = () => {
    emitEvent(EVENTS.HANDLE_TRANSACTION, {
      type: TRANSACTION_TYPE.UNSTAKE,
      amount: stakeAmount,
      errorMessages: errorMessages,
    });
  };

  const { data: tvlData } = useTVLQuery();
  const { approvedTokenX, tokenXSymbol } = useSelector(
    (state: any) => state.user,
  );

  const maxStakeValue = cmsData?.max_stake_value ? cmsData.max_stake_value : -1;

  const openWalletModal = () => {
    openConnectModal?.();
  };
  const { isValidChain: status, switchChain, chain } = useChainData();

  const NON_APPROVAL_CHAINS =
    type === 'stake' ? NON_APPROVAL_CHAINS_STAKE : NON_APPROVAL_CHAINS_UNSTAKE;

  const associated = useMemo(() => {
    return tokenXAssociated;
  }, [tokenAssociated, tokenXAssociated, type]);

  const allowances = useMemo(() => {
    const value = type === 'stake' ? tokenAllowance : tokenXAllowance;

    return value?.formatted;
  }, [type, tokenAllowance, tokenXAllowance]);

  const isApproved = useMemo(() => {
    let amount = type === 'stake' ? stakeAmount : unstakeAmount;
    if (!amount) {
      amount = '0';
    }

    return (
      NON_APPROVAL_CHAINS.includes(token) ||
      (allowances && Number(allowances) && Number(allowances) >= Number(amount))
    );
  }, [
    NON_APPROVAL_CHAINS,
    allowances,
    stakeAmount,
    token,
    type,
    unstakeAmount,
    approvedTokenX,
  ]);

  const getButtonText = () => {
    if (!isConnected) {
      return walletConnectText;
    } else {
      if (!status && switchNetwork) {
        return switchNetworkText;
      } else if (!associated) {
        return `Associate ${tokenXSymbol}`;
      } else if (isApproved) {
        return primaryActionText;
      }
      return approveActionText;
    }
  };

  const getStakeUnstakeClick = (type: string) => {
    if (type === 'stake') {
      if (STAKE_CONFIRMATION_CHAINS.includes(token)) {
        setIsVisibleStakeConfirmation('stake');
      } else {
        handleStake();
      }
    } else {
      if (
        UNSTAKE_CONFIRMATION_CHAINS.includes(token) &&
        unstakeLimits.max &&
        unstakeLimits.max <= unstakeAmount
      ) {
        setIsVisibleUnstakeConfirmation(true);
      } else {
        handleUnstake();
      }
    }
  };

  const handleClick = () => {
    const amount = type === 'stake' ? stakeAmount : unstakeAmount;
    if (!isConnected) {
      trackEvent(CONNECT_WALLET, {
        wallet_cta: type,
      });
      openWalletModal();
    } else {
      if (!status && switchNetwork) {
        switchChain();
      } else if (!associated) {
        handleAssociateToken();
      } else if (isApproved) {
        if (!amount || Number(amount) === 0) {
          setError(
            "This field can't be blank or zero. Please provide valid input.",
          );
          return;
        }

        dispatch(
          setApprovalData({
            status:
              type === 'stake' && chain?.custom?.isNativeChain
                ? TXN_STAGE_APPROVAL_STATUS.NATIVE
                : TXN_STAGE_APPROVAL_STATUS.ALREADY_APPROVED,
          }),
        );
        getStakeUnstakeClick(type);
      } else {
        if (!amount || Number(amount) === 0) {
          setError(
            "This field can't be blank or zero. Please provide valid input.",
          );
          return;
        }
        trackEvent(`approve_${type === 'stake' ? 'staking' : 'unstaking'}`);

        if (type === 'stake') {
          if (STAKE_CONFIRMATION_CHAINS.includes(token)) {
            setIsVisibleStakeConfirmation('approve');
          } else {
            handleERC20Approve();
          }
        } else {
          handleXTokenApprove();
        }
      }
    }
  };

  const TVL = useMemo(() => {
    return tvlData?.value ?? undefined;
  }, [tvlData]);

  return (
    <Flex gap="16px" flexDir={{ base: 'column-reverse', md: 'row' }} mt="2rem">
      {secondaryText && isConnected ? (
        <Button
          variant="none"
          background="transparent"
          border="1px solid"
          borderColor="seperator"
          color="textPrimary"
          _light={{
            color: 'primary',
            borderColor: 'primary',
          }}
          height="56px"
          fontWeight="600"
          fontSize="16px"
          borderRadius="12px"
          w="100%"
          as="a"
          target="_blank"
          rel="noreferrer"
          href={secondaryLink || ''}
          flex={{ base: '1 1 auto', md: '1 1 50%' }}
          onClick={() => {
            trackEvent(EVENTS.HANDLE_TRANSACTION, {
              type: TRANSACTION_TYPE.SWAP,
            });
          }}
        >
          {secondaryText}
        </Button>
      ) : null}

      <Button
        color="white"
        height="56px"
        w="100%"
        fontWeight="600"
        fontSize="16px"
        borderRadius="12px"
        flexBasis="100%"
        flex={{ base: '1 1 auto', md: '1 1 50%' }}
        isDisabled={
          isLoading ||
          isDisabled ||
          (isConnected &&
            (!!error ||
              isStaking ||
              loadingERC20 ||
              isUnstaking ||
              loadingXToken)) ||
          (isConnected &&
            type === 'stake' &&
            maxStakeValue > 0 &&
            TVL !== undefined &&
            (TVL > maxStakeValue ||
              (stakeAmount
                ? Number(stakeAmount) + TVL > maxStakeValue
                : false)))
        }
        onClick={() => handleClick()}
      >
        {isLoading ||
        isStaking ||
        loadingERC20 ||
        isUnstaking ||
        loadingXToken ? (
          <Spinner size="md" color="white" />
        ) : (
          <Text>{getButtonText()}</Text>
        )}
      </Button>
      {isVisibleUnstakeConfirmation && (
        <UnstakeLimitExceedModal
          isOpen={isVisibleUnstakeConfirmation}
          closeAlert={() => setIsVisibleUnstakeConfirmation(false)}
          modalTitle="Exceeding withdrawal limit"
          modalSubTitle="You are attempting to withdraw an amount exceeding the current withdrawal limit. Your withdrawal request will be placed in the queue, and it will be processed as soon as sufficient SD become available in the SD Utility Pool."
          primaryBtnTxt="Confirm withdraw"
          onSubmitPrimary={() => {
            setIsVisibleUnstakeConfirmation(false);
            handleUnstake();
            // primaryActionCallBack && primaryActionCallBack();
          }}
          token={token}
        />
      )}

      {isVisibleStakeConfirmation && (
        <StakeDiclaimer
          isOpen={isVisibleStakeConfirmation != null}
          closeAlert={() => setIsVisibleStakeConfirmation(null)}
          primaryBtnTxt={`Delegate ${token}`}
          onSubmitPrimary={() => {
            if (isVisibleStakeConfirmation === 'approve') {
              handleERC20Approve();
            } else if (isVisibleStakeConfirmation === 'stake') {
              handleStake();
            }
            setIsVisibleStakeConfirmation(null);
            // primaryActionCallBack && primaryActionCallBack();
          }}
        />
      )}
    </Flex>
  );
};

export default TicketWalletConnect;
