import { Center, Text, ToastPosition, useToast } from '@chakra-ui/react';

import { ErrorToast, SuccessCheck } from '@/icons';

import { TransactionType } from '../../constants/common';
import CloseIcon from '../CloseIcon/CloseIcon';
import Icon from '../Icon';
import { ModalDrawer } from '../ModalDrawer';
import SuccessErrorModalBodyTemplate from '../SuccessErrorModalBodyTemplate';

interface WalletMessageModalProps {
  isOpen: boolean;
  error: string | null;
  onSubmitPrimary: () => void;
  closeModal: () => void;
  isWrongChain: boolean;
  isEmbed: boolean;
}

const defaultToastProps = {
  duration: 5000,
  position: 'top' as ToastPosition,
  isClosable: true,
};

const WalletMessageModal = ({
  isOpen,
  error,
  isWrongChain,
  isEmbed,
  onSubmitPrimary,
  closeModal,
}: WalletMessageModalProps) => {
  const toast = useToast();

  const renderContent = () => {
    if (error) {
      if (!toast.isActive('error-toast')) {
        toast({
          description: (
            <Text fontWeight={600} fontSize="16px">
              {error}
            </Text>
          ),
          status: 'error',
          icon: <Icon Icon={ErrorToast} width="24px" height="24px" />,
          id: 'error-toast',
          onCloseComplete: closeModal,
          ...defaultToastProps,
        });
      }
      return <></>;
    } else {
      if (isWrongChain && !isEmbed) {
        return (
          <ModalDrawer isOpen={isOpen} closeAlert={closeModal}>
            <Center
              padding={{ base: '0 1rem 2.5rem', md: '2.5rem 3rem' }}
              fontWeight="600"
              flexDirection="column"
            >
              {
                <>
                  <CloseIcon onClose={closeModal} />
                  <SuccessErrorModalBodyTemplate
                    transactionType={TransactionType.WARNING}
                    modalTitle="Unsupported network"
                    primaryBtnTxt="Switch Network"
                    onSubmitPrimary={() => onSubmitPrimary()}
                  />
                </>
              }
            </Center>
          </ModalDrawer>
        );
      }
      if (!toast.isActive('success-toast')) {
        toast({
          description: (
            <Text fontWeight={600} fontSize="16px">
              Wallet connected successfully
            </Text>
          ),
          status: 'success',
          icon: (
            <Icon
              Icon={SuccessCheck}
              width="24px"
              height="24px"
              color="white"
            />
          ),
          id: 'success-toast',
          onCloseComplete: closeModal,
          ...defaultToastProps,
        });
      }
      return <></>;
    }
  };

  if (TransactionType.SUCCESS && isEmbed) {
    return null;
  }

  return <>{renderContent()}</>;
};

export default WalletMessageModal;
