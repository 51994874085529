import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Link,
  Spinner,
  Step,
  StepDescription,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { useExchangeRateQuery } from '@stader-labs/web-sdk';
import BigNumber from 'bignumber.js';
import { usePathname } from 'next/navigation';
import { FaCheck } from 'react-icons/fa6';
import { IoMdOpen } from 'react-icons/io';
import { IoClose, IoReloadOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { ValueOf } from 'type-fest';

import Icon from '@/components/Icon';
import { SD } from '@/constants/constants';
import { defiCurrencyIconMapping } from '@/constants/dappWalletMapping';
import {
  TXN_STAGE_APPROVAL_STATUS,
  TXN_STAGE_STEP,
  TXN_STAGE_TYPE,
} from '@/constants/stake-page.constant';
import { useChainData } from '@/providers/ChainDataProvider';
import { RootState } from '@/store';
import { StakePageState } from '@/store/slices/stake-page.slice';
import { getCurrentChain, toFixedWithoutRounding } from '@/utils/common';

interface ReviewTransactionProps {
  hash?: string;
  onRetry: () => void;
}

const ReviewTransaction: React.FC<ReviewTransactionProps> = (props) => {
  const pathname = usePathname();
  const dappName = getCurrentChain(pathname);
  const [amountLocal, setAmountLocal] = useState<BigNumber | null>(null);
  const { contractConfig, chain } = useChainData();
  const txnDetails = useSelector(
    (state: RootState) => state.stakePage.txnDetails,
  );
  const approvalData = useSelector(
    (state: RootState) => state.stakePage.approvalData,
  );

  const actionData = useSelector(
    (state: RootState) => state.stakePage.actionData,
  );

  const amount = useSelector((state: RootState) => {
    if (state.stakePage.txnDetails == null) {
      return BigNumber(0);
    }

    if (state.stakePage.txnDetails.type === TXN_STAGE_TYPE.STAKE) {
      return BigNumber(state.stake.stakeAmount);
    }

    return BigNumber(state.stake.unstakeAmount);
  });

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (amountLocal === null) {
      setAmountLocal(amount);
    }
  }, [amount, amountLocal]);

  const stakeToken = useSelector(
    (state: RootState) => state.stakePage.stakeToken,
  );
  const { data: exchangeRate } = useExchangeRateQuery({
    enabled: dappName != SD,
  });

  const exchangeAmount = useMemo(() => {
    if (amountLocal == null || exchangeRate == null) {
      return BigNumber(0);
    }

    if (txnDetails?.type === TXN_STAGE_TYPE.STAKE) {
      return amountLocal.dividedBy(exchangeRate);
    }

    if (txnDetails?.type === TXN_STAGE_TYPE.UNSTAKE) {
      return amountLocal.multipliedBy(exchangeRate);
    }
  }, [amountLocal, exchangeRate, txnDetails?.type]);

  const getExplorerUrl = useCallback(
    (hash: string | null) => {
      if (chain == null || hash == null) {
        return '';
      }

      return `${chain.blockExplorers?.default?.url}/tx/${hash}`;
    },
    [chain],
  );

  const [inToken, outToken] = useMemo(() => {
    if (txnDetails?.type === TXN_STAGE_TYPE.STAKE) {
      return [stakeToken, contractConfig?.xToken];
    }

    return [contractConfig?.xToken, stakeToken];
  }, [contractConfig?.xToken, stakeToken, txnDetails?.type]);

  const stepsData = useMemo(() => {
    const getActionTitle = () => {
      if (txnDetails?.type === TXN_STAGE_TYPE.STAKE) {
        return dappName === SD ? 'Delegate' : 'Stake';
      }

      return dappName === SD ? 'Withdraw' : 'Unstake';
    };

    return [
      {
        title: `Approve ${inToken?.symbol}`,
        description: getApprovalText({
          step: txnDetails?.step ?? null,
          approvalData,
          explorerUrl: getExplorerUrl(approvalData.hash),
          onRetry: props.onRetry,
        }),
        isError: !!approvalData.error,
      },
      {
        title: `Confirm ${getActionTitle()}`,
        description: getActionText({
          step: txnDetails?.step ?? null,
          actionData,
          explorerUrl: getExplorerUrl(actionData.hash),
          onRetry: props.onRetry,
        }),
        isError: !!actionData.error,
      },
    ];
  }, [
    actionData,
    approvalData,
    getExplorerUrl,
    inToken?.symbol,
    props.onRetry,
    txnDetails?.step,
    txnDetails?.type,
    dappName,
  ]);

  const modalTitleAction = useMemo(() => {
    if (txnDetails?.type === TXN_STAGE_TYPE.STAKE) {
      return dappName === SD ? 'Delegate' : 'Stake';
    }

    return dappName === SD ? 'Withdraw' : 'Unstake';
  }, [txnDetails?.type, dappName]);

  const renderTokenSection = useCallback(
    ({
      tokenSymbol,
      amount,
      actionText,
    }: {
      tokenSymbol: string | undefined;
      amount: BigNumber | undefined | null;
      actionText: string;
    }) => (
      <Flex
        direction="column"
        alignItems={{ base: 'flex-start', md: 'center' }}
        flex={1}
        gap={'6px'}
      >
        <Text fontSize="xs" color="textSecondary">
          You {actionText}
        </Text>

        <Flex gap={'6px'} alignItems="center" flex={1}>
          {tokenSymbol &&
            defiCurrencyIconMapping[tokenSymbol.toLowerCase()] && (
              <Icon
                width="32px"
                height="32px"
                Icon={
                  defiCurrencyIconMapping[tokenSymbol.toLowerCase()][colorMode]
                }
              />
            )}
          <Text fontSize="1.5rem">
            {toFixedWithoutRounding(amount ?? 0, 4)} {tokenSymbol}
          </Text>
        </Flex>
      </Flex>
    ),
    [colorMode],
  );

  return (
    <Flex direction="column" alignItems="start" gap={8} width="100%">
      <Text as="h3" fontSize="1.5rem">
        Review {modalTitleAction}
      </Text>

      <Flex
        width="100%"
        bgColor="backgroundPrimary"
        borderRadius={8}
        px={6}
        py={4}
        justifyContent={'space-between'}
        gap={4}
        direction={{ base: 'column', md: 'row' }}
      >
        {
          // * For token stake and unstake - You send
          dappName !== SD &&
            renderTokenSection({
              tokenSymbol: inToken?.symbol,
              amount: amountLocal,
              actionText: 'send',
            })
        }

        {
          // * For token stake and unstake - You receive
          dappName !== SD &&
            renderTokenSection({
              tokenSymbol: outToken?.symbol,
              amount: exchangeAmount,
              actionText: 'receive',
            })
        }

        {
          // * For SD delegation - You send
          txnDetails?.type === TXN_STAGE_TYPE.STAKE &&
            dappName === SD &&
            renderTokenSection({
              tokenSymbol: inToken?.symbol,
              amount: amountLocal,
              actionText: 'delegate',
            })
        }

        {
          // * For SD withdrawal - You receive
          txnDetails?.type === TXN_STAGE_TYPE.UNSTAKE &&
            dappName === SD &&
            renderTokenSection({
              tokenSymbol: outToken?.symbol,
              amount: amountLocal,
              actionText: 'receive',
            })
        }
      </Flex>

      {txnDetails != null && (
        <Stepper
          index={txnDetails.step === TXN_STAGE_STEP.APPROVE ? 0 : 1}
          orientation="vertical"
          minHeight="140px"
          gap="0"
          colorScheme="green"
        >
          {stepsData.map((step, index) => (
            <Box as={Step} key={index} pb={4}>
              <StepIndicator
                borderColor={step.isError ? '#E53E3E !important' : undefined}
              >
                <StepStatus
                  incomplete={<StepNumber />}
                  active={
                    step.isError ? (
                      <IoClose size={20} color="#E53E3E" />
                    ) : (
                      <Spinner size="sm" color="#07A658" />
                    )
                  }
                  complete={<FaCheck />}
                />
              </StepIndicator>

              <Box flexShrink="0" maxW="466px">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Box>
          ))}
        </Stepper>
      )}
    </Flex>
  );
};

const getApprovalText = (opt: {
  step: ValueOf<typeof TXN_STAGE_STEP> | null;
  approvalData: StakePageState['approvalData'];
  explorerUrl: string;
  onRetry: () => void;
}): string | React.ReactNode => {
  const { approvalData, onRetry } = opt;

  if (approvalData.status === TXN_STAGE_APPROVAL_STATUS.NATIVE) {
    return 'Approval is not required for native assets';
  }

  if (approvalData.status === TXN_STAGE_APPROVAL_STATUS.ALREADY_APPROVED) {
    return 'Approval has already been granted.';
  }

  if (approvalData.hash) {
    if (opt.step === TXN_STAGE_STEP.ACTION) {
      return (
        <Text as="span">
          Approval has been successfully granted.{' '}
          <Button
            as={Link}
            href={opt.explorerUrl}
            size="xs"
            _hover={{
              textDecoration: 'none',
            }}
            isExternal
            rightIcon={<IoMdOpen />}
          >
            View transaction
          </Button>
        </Text>
      );
    }

    return (
      <Text as="span">
        Your transaction is in progress{' '}
        <Button
          as={Link}
          href={opt.explorerUrl}
          size="xs"
          _hover={{
            textDecoration: 'none',
          }}
          isExternal
          rightIcon={<IoMdOpen />}
        >
          View transaction
        </Button>
      </Text>
    );
  }

  if (approvalData.error) {
    return (
      <>
        <Text as="span" color="red.500">
          {approvalData.error}
        </Text>{' '}
        <Button size="xs" leftIcon={<IoReloadOutline />} onClick={onRetry}>
          Retry
        </Button>
      </>
    );
  }

  return 'Check your wallet to proceed';
};

const getActionText = (opt: {
  step: ValueOf<typeof TXN_STAGE_STEP> | null;
  actionData: StakePageState['actionData'];
  explorerUrl: string;
  onRetry: () => void;
}) => {
  if (opt.step !== TXN_STAGE_STEP.ACTION) {
    return (
      <Text as="span" color="textTertiary">
        Complete the token approval above to proceed
      </Text>
    );
  }

  if (opt.actionData.hash) {
    return (
      <Text as="span">
        Your transaction is in progress{' '}
        <Button
          as={Link}
          href={opt.explorerUrl}
          size="xs"
          _hover={{
            textDecoration: 'none',
          }}
          isExternal
          rightIcon={<IoMdOpen />}
        >
          View transaction
        </Button>
      </Text>
    );
  }

  if (opt.actionData.error) {
    return (
      <>
        <Text as="span" color="red.500">
          {opt.actionData.error}
        </Text>{' '}
        <Button size="xs" leftIcon={<IoReloadOutline />} onClick={opt.onRetry}>
          Retry
        </Button>
      </>
    );
  }

  return 'Check your wallet to proceed';
};

export { ReviewTransaction };
