import { Hash } from 'viem';
import {
  Chain,
  arbitrum,
  arbitrumSepolia,
  bsc,
  bscTestnet,
  goerli,
  holesky,
  mainnet,
  polygon,
  polygonAmoy,
  sepolia,
  xLayer,
  xLayerTestnet,
} from 'viem/chains';

import { BNB, ETH, POLYGON, SD } from '@/constants/constants';
import { env } from '@/env';

export interface ContractTokenConfig {
  symbol: string;
  /**
   * undefined for native currency
   */
  address?: Hash;
  decimals: number;
  data?: {
    imageUrl: string;
    name: string;
  };
}

export interface ContractsConfig {
  stakeTokens: [ContractTokenConfig, ...ContractTokenConfig[]];
  xToken: ContractTokenConfig;
  isWithdrawSupported: boolean;
}

const MAINNET_TOKENS: Record<string, Record<Chain['id'], ContractsConfig>> = {
  [ETH]: {
    [mainnet.id]: {
      stakeTokens: [
        {
          symbol: mainnet.nativeCurrency.symbol,
          address: undefined,
          decimals: mainnet.nativeCurrency.decimals,
        },
      ],
      xToken: {
        symbol: 'ETHx',
        address: '0xA35b1B31Ce002FBF2058D22F30f95D405200A15b',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
    [arbitrum.id]: {
      stakeTokens: [
        {
          symbol: arbitrum.nativeCurrency.symbol,
          address: undefined,
          decimals: arbitrum.nativeCurrency.decimals,
        },
      ],
      xToken: {
        symbol: 'ETHx',
        address: '0xED65C5085a18Fa160Af0313E60dcc7905E944Dc7',
        decimals: 18,
      },
      isWithdrawSupported: false,
    },
    [xLayer.id]: {
      stakeTokens: [
        {
          symbol: 'WETH',
          address: '0x5a77f1443d16ee5761d310e38b62f77f726bc71c',
          decimals: 18,
        },
      ],
      xToken: {
        symbol: 'WETHx',
        address: '0xDBAaD20ffd67dfaeBdE40b842cB78eAa18F1BB74',
        decimals: 18,
      },
      isWithdrawSupported: false,
    },
  },
  [BNB]: {
    [bsc.id]: {
      stakeTokens: [
        {
          symbol: bsc.nativeCurrency.symbol,
          address: undefined,
          decimals: mainnet.nativeCurrency.decimals,
        },
      ],
      xToken: {
        symbol: 'BNBx',
        address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
  },
  [POLYGON]: {
    [polygon.id]: {
      stakeTokens: [
        {
          symbol: 'POL',
          address: undefined,
          decimals: polygon.nativeCurrency.decimals,
          data: {
            imageUrl: '/assets/images/chains/pol-ex-matic.svg',
            name: 'POL (ex-MATIC)',
          },
        },
      ],
      xToken: {
        symbol: 'MaticX',
        address: '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
    [mainnet.id]: {
      stakeTokens: [
        {
          symbol: 'POL',
          address: '0x455e53CBB86018Ac2B8092FdCd39d8444aFFC3F6',
          decimals: polygon.nativeCurrency.decimals,
          data: {
            imageUrl: '/assets/images/chains/pol-ex-matic.svg',
            name: 'POL (ex-MATIC)',
          },
        },
        {
          symbol: 'MATIC',
          address: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
          decimals: 18,
          data: {
            imageUrl: '/assets/images/chains/polygon.svg',
            name: 'Polygon',
          },
        },
      ],
      xToken: {
        symbol: 'MaticX',
        address: '0xf03a7eb46d01d9ecaa104558c732cf82f6b6b645',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
  },
  [SD]: {
    [mainnet.id]: {
      stakeTokens: [
        {
          symbol: 'SD',
          address: '0x30D20208d987713f46DFD34EF128Bb16C404D10f',
          decimals: 18,
          data: {
            imageUrl: '/assets/images/sd.svg',
            name: 'Stader',
          },
        },
      ],
      xToken: {
        symbol: 'SD',
        address: '0x30D20208d987713f46DFD34EF128Bb16C404D10f',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
  },
} as const;

const TESTNET_TOKENS: Record<string, Record<Chain['id'], ContractsConfig>> = {
  [ETH]: {
    [holesky.id]: {
      stakeTokens: [
        {
          symbol: holesky.nativeCurrency.symbol,
          address: undefined,
          decimals: holesky.nativeCurrency.decimals,
        },
      ],
      xToken: {
        symbol: 'ETHx',
        address: '0xFFF6552297ce1433F28b91C98338CECf06CAED08',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
    [arbitrumSepolia.id]: {
      stakeTokens: [
        {
          symbol: arbitrumSepolia.nativeCurrency.symbol,
          address: undefined,
          decimals: arbitrumSepolia.nativeCurrency.decimals,
        },
      ],
      xToken: {
        symbol: 'ETHx',
        address: '0x52312ea29135A468417F0C71d6A75CfEA75351b7',
        decimals: 18,
      },
      isWithdrawSupported: false,
    },
    [xLayerTestnet.id]: {
      stakeTokens: [
        {
          symbol: 'WETH',
          address: '0xBec7859BC3d0603BeC454F7194173E36BF2Aa5C8',
          decimals: 18,
        },
      ],
      xToken: {
        symbol: 'WETHx',
        address: '0x0B2fAadfe75fE6507Dc8F81f8331198C65cA2C24',
        decimals: 18,
      },
      isWithdrawSupported: false,
    },
  },
  [BNB]: {
    [bscTestnet.id]: {
      stakeTokens: [
        {
          symbol: 'BNB',
          address: undefined,
          decimals: 18,
        },
      ],
      xToken: {
        symbol: 'BNBx',
        address: '0x4e16D7Bb32a44e9D43053C86617fbc63223A226C',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
  },
  [POLYGON]: {
    [polygonAmoy.id]: {
      stakeTokens: [
        {
          symbol: 'POL',
          address: undefined,
          decimals: polygonAmoy.nativeCurrency.decimals,
          data: {
            imageUrl: '/assets/images/chains/pol-ex-matic.svg',
            name: 'POL (ex-MATIC)',
          },
        },
      ],
      xToken: {
        symbol: 'MaticX',
        address: '0xFD837d45dd6Af18D039122301C3331D4A4307351',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
    [sepolia.id]: {
      stakeTokens: [
        {
          symbol: 'POL',
          address: '0x44499312f493F62f2DFd3C6435Ca3603EbFCeeBa',
          decimals: polygon.nativeCurrency.decimals,
          data: {
            imageUrl: '/assets/images/chains/pol-ex-matic.svg',
            name: 'POL (ex-MATIC)',
          },
        },
        {
          symbol: 'MATIC',
          address: '0x3fd0A53F4Bf853985a95F4Eb3F9C9FDE1F8e2b53',
          decimals: 18,
          data: {
            imageUrl: '/assets/images/chains/polygon.svg',
            name: 'Polygon',
          },
        },
      ],
      xToken: {
        symbol: 'MaticX',
        address: '0xB51AAb302085F436204c4B1964fBE74517B2D4b6',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
  },
  [SD]: {
    // ! Ethereum Goerli is deprecated
    [goerli.id]: {
      stakeTokens: [
        {
          symbol: 'SD',
          address: '0x0000000000000000000000000000000deadbeef',
          decimals: 18,
        },
      ],
      xToken: {
        symbol: 'SD',
        address: '0x0000000000000000000000000000000deadbeef',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
  },
} as const;

const PREPROD_MAINNET_TOKENS: Record<
  string,
  Record<Chain['id'], ContractsConfig>
> = {
  // ? Add default config for d-apps that doesn't have preprod
  ...MAINNET_TOKENS,
  [POLYGON]: {
    [mainnet.id]: {
      stakeTokens: [
        {
          symbol: 'POL',
          address: '0x455e53CBB86018Ac2B8092FdCd39d8444aFFC3F6',
          decimals: polygon.nativeCurrency.decimals,
          data: {
            imageUrl: '/assets/images/chains/pol-ex-matic.svg',
            name: 'POL (ex-MATIC)',
          },
        },
        {
          symbol: 'MATIC',
          address: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
          decimals: 18,
          data: {
            imageUrl: '/assets/images/chains/polygon.svg',
            name: 'Polygon',
          },
        },
      ],
      xToken: {
        symbol: 'MaticX',
        address: '0xf03a7eb46d01d9ecaa104558c732cf82f6b6b645',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
    [polygon.id]: {
      stakeTokens: [
        {
          symbol: 'POL',
          address: undefined,
          decimals: polygon.nativeCurrency.decimals,
          data: {
            imageUrl: '/assets/images/chains/pol-ex-matic.svg',
            name: 'POL (ex-MATIC)',
          },
        },
      ],
      xToken: {
        symbol: 'MaticX',
        address: '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6',
        decimals: 18,
      },
      isWithdrawSupported: true,
    },
  },
};

const network = env.NEXT_PUBLIC_NETWORK;
const token = env.NEXT_PUBLIC_TOKEN;

const getConfigForEnv = <T = object>({
  mainnet,
  testnet,
  preprodMainnet,
}: {
  mainnet: Record<Chain['id'], T>;
  testnet: Record<Chain['id'], T>;
  preprodMainnet: Record<Chain['id'], T>;
}): Record<Chain['id'], T> => {
  if (network === 'testnet') {
    return testnet;
  }

  if (network === 'preprod-mainnet') {
    return preprodMainnet;
  }

  return mainnet;
};

export const CONTRACTS_CONFIG = getConfigForEnv<ContractsConfig>({
  mainnet: MAINNET_TOKENS[token],
  testnet: TESTNET_TOKENS[token],
  preprodMainnet: PREPROD_MAINNET_TOKENS[token],
});
