import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface StakeStateProps {
  isMobileMenuOpen: boolean;
  blockExplorerURL: string;
  modalType: string | null;
  isWalletModalOpen: boolean;
  withdrawals: any[];
  stakeAmount: string;
  unstakeAmount: string;
  promptError: string;
  latestTxnHash: string;
  txnLoader: boolean;
  isStaking: boolean;
  isUnstaking: boolean;
  tokenAllowence: string | null;
  tokenXAllowence: string | null;
  tokenAssociated: boolean;
  tokenXAssociated: boolean;
  approveTokenLoading: boolean;
  approveTokenXLoading: boolean;
  gasFeesData: any;
  limits: any;
  isRetryTransaction: boolean;
  ledgerTxSuccessFormDetails: any;
}

export const initialStakeState: StakeStateProps = {
  isMobileMenuOpen: false,
  blockExplorerURL: '',
  modalType: null,
  isWalletModalOpen: false,
  withdrawals: [],
  stakeAmount: '',
  unstakeAmount: '',
  promptError: '',
  latestTxnHash: '',
  txnLoader: false,
  isStaking: false,
  isUnstaking: false,
  approveTokenLoading: false,
  approveTokenXLoading: false,
  tokenAssociated: true,
  tokenXAssociated: true,
  tokenAllowence: null,
  tokenXAllowence: null,
  gasFeesData: null,
  limits: null,
  isRetryTransaction: false,
  ledgerTxSuccessFormDetails: null,
};

const EXCEPTION_KEYS = [
  'nodeOperatorsBond',
  'nodeOperatorsCount',
  'ratedNetworkOverview',
  'prices',
  'limits',
  'blockExplorerURL',
];

export const stakeSlice = createSlice({
  name: 'stake',
  initialState: initialStakeState,
  reducers: {
    updateStakeData: (state: any, action: any) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    clearStakeData: (state: any) => {
      for (const [key, value] of Object.entries(initialStakeState)) {
        if (!EXCEPTION_KEYS.includes(key)) state[key] = value;
      }
    },

    setStakeAmount: (state: StakeStateProps, action: PayloadAction<string>) => {
      state.stakeAmount = action.payload;
    },

    setUnstakeAmount: (
      state: StakeStateProps,
      action: PayloadAction<string>,
    ) => {
      state.unstakeAmount = action.payload;
    },
  },
});

export const { updateStakeData, clearStakeData }: any = stakeSlice.actions;

export const { setStakeAmount, setUnstakeAmount } = stakeSlice.actions;

export default stakeSlice.reducer;
