export const SENTRY_TAGS = {
  /**
   * Usually the name of the function
   */
  OPERATION: 'operation',
  /**
   * usually NPM package name associated with the function
   */
  MODULE: 'module',
  /**
   * Type of event
   * @example analytics, web3
   */
  TYPE: 'type',
} as const;

export const SENTRY_TAGS_TYPE_VALUES = {
  ANALYTICS: 'analytics',
  WEB3: 'web3',
  EXTERNAL_API: 'external-api',
  INTERNAL_API: 'internal-api',
  CMS: 'cms',
  REACT_QUERY: 'react-query',
  ERROR_BOUNDARY: 'error-boundary',
  /**
   * General tag for JS operation or calculation
   */
  GENERAL: 'general',
} as const;
