import { useRouter } from 'next/router';
import { useAccount } from 'wagmi';

import { WALLET_KEYS } from '../constants/common';

export const useEmbed = () => {
  const { connector } = useAccount();
  const router = useRouter();

  const isLedger = Boolean(router.query.embed);

  const isSafeApp = connector?.id === WALLET_KEYS.SAFE;
  const isEmbed = connector?.id === WALLET_KEYS.LEDGER_LIVE || isLedger;

  return { isEmbed, isSafeApp };
};
