import { captureException } from '@sentry/nextjs';
import {
  AnalyticsCallOptions,
  EventParams,
  getAnalytics,
  logEvent,
} from 'firebase/analytics';
import { FirebaseApp, getApps, initializeApp } from 'firebase/app';

import { CONTRACTS_CONFIG } from '@/config/contracts.config';
import {
  SENTRY_TAGS,
  SENTRY_TAGS_TYPE_VALUES,
} from '@/constants/monitoring.constants';
import { store } from '@/store';

import { firebaseConfig } from '../config/firebase';
import { STADER_VERSION, VERSION } from '../constants/analytics';
import { BNB, ETH, FANTOM, HEDERA, POLYGON, SD } from '../constants/constants';
import {
  WALLET_CONNECTED_SUCCESSFULLY,
  WALLET_CONNECTION_FAILED,
} from '../constants/firebase';
import { walletLabelMapping } from '../constants/walletMenuOptions';

// Utils

let _fbApp: FirebaseApp | undefined = undefined;

const getFirebaseConfig = (chain: string) => {
  switch (chain) {
    case SD:
      return firebaseConfig.sdConfig;
    case ETH:
      return firebaseConfig.ethConfig;
    case BNB:
      return firebaseConfig.bnbConfig;
    case POLYGON:
      return firebaseConfig.polygonConfig;
    case HEDERA:
      return firebaseConfig.hederaConfig;
    case FANTOM:
      return firebaseConfig.fantomConfig;
  }
};

const getDappName = (chain: string) => {
  switch (chain) {
    case SD:
      return 'SD';
    case ETH:
      return 'Ethereum';
    case BNB:
      return 'BNB';
    case POLYGON:
      return 'Polygon';
    case FANTOM:
      return 'Fantom';
    case HEDERA:
      return 'Hedera';
  }
};

if (typeof window !== 'undefined' && getApps().length <= 0) {
  _fbApp = initializeApp(
    getFirebaseConfig(process.env.NEXT_PUBLIC_TOKEN || ''),
    {
      automaticDataCollectionEnabled: true,
    },
  );
}

const checkWalletName = (isConnected: boolean, wallet_name: string) => {
  if (!isConnected && wallet_name === walletLabelMapping.ledgerLive) {
    return '';
  }
  return wallet_name;
};

export const trackEvent = (
  eventName: string,
  eventParams: EventParams = {},
  options?: AnalyticsCallOptions,
) => {
  const { user, event, wallet, stakePage } = store.getState();
  const { firebaseEventsData } = event;
  const { isConnected } = wallet;

  const chainData = wallet.chainConfig
    ? CONTRACTS_CONFIG[wallet.chainConfig.id]
    : null;

  let userData = {
    chain_id: wallet.chainConfig?.id ?? 'not available',
    chain_name: wallet.chainConfig?.name ?? 'not available',
    token_balance: user.tokenAmount,
    token_x_balance: user.tokenXAmount,
    token_symbol: stakePage.stakeToken?.symbol ?? 'not available',
    token_x_symbol: chainData?.xToken?.symbol ?? 'not available',
    ...firebaseEventsData,
    wallet_name: checkWalletName(isConnected, firebaseEventsData.wallet_name),
  };
  if (
    eventName === WALLET_CONNECTED_SUCCESSFULLY ||
    eventName === WALLET_CONNECTION_FAILED
  ) {
    userData = {
      ...userData,
      ...eventParams,
    };
  }
  if (user.isEmbed) {
    userData.wallet_name = walletLabelMapping.ledgerLive;
  }
  try {
    const analytics = getAnalytics(_fbApp);
    logEvent(
      analytics,
      eventName,
      {
        ...eventParams,
        dapp: getDappName(process.env.NEXT_PUBLIC_TOKEN || ''),
        [STADER_VERSION]: VERSION,
        ...userData,
      },
      options,
    );
    (window as any).dataLayer?.push({
      event: eventName,
      ...eventParams,
      dapp: getDappName(process.env.NEXT_PUBLIC_TOKEN || ''),
      [STADER_VERSION]: VERSION,
      ...userData,
    });
  } catch (err) {
    captureException(err, {
      tags: {
        [SENTRY_TAGS.TYPE]: SENTRY_TAGS_TYPE_VALUES.ANALYTICS,
        [SENTRY_TAGS.MODULE]: 'firebase',
        [SENTRY_TAGS.OPERATION]: 'trackEvent',
      },
    });
  }

  try {
    (window as any).rudderanalytics.track(eventName, {
      ...eventParams,
      dapp: getDappName(process.env.NEXT_PUBLIC_TOKEN || ''),
      [STADER_VERSION]: VERSION,
      ...userData,
    });
  } catch (err) {
    captureException(err, {
      tags: {
        [SENTRY_TAGS.TYPE]: SENTRY_TAGS_TYPE_VALUES.ANALYTICS,
        [SENTRY_TAGS.MODULE]: 'rudderstack',
        [SENTRY_TAGS.OPERATION]: 'trackEvent',
      },
    });
  }
};
