import { ReactNode, createContext, useContext } from 'react';

import { useChainDataHook } from '@/hooks/useChainData';

const ChainDataContext = createContext<ReturnType<
  typeof useChainDataHook
> | null>(null);

const ChainDataProvider = ({ children }: { children: ReactNode }) => {
  const chainData = useChainDataHook();
  return (
    <ChainDataContext.Provider value={chainData}>
      {children}
    </ChainDataContext.Provider>
  );
};

const useChainData = () => {
  const context = useContext(ChainDataContext);
  if (!context) {
    throw new Error('useChainData must be used within a ChainDataProvider');
  }
  return context;
};

export { ChainDataProvider, useChainData };
