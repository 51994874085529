import { formatEther } from '@ethersproject/units';
import { TOKEN } from '@stader-labs/web-sdk';
import { fetchBalance, getAccount } from '@wagmi/core';

import { store, updateEventData } from '@/store';

import { NetworkConfigType } from '../config/types';
import { POLYGON } from '../constants/constants';
import { IIndexable, Token } from '../types/common';

const isIPFS = !!process.env.NEXT_PUBLIC_IS_IPFS;
const SUB_ROUTE = process.env.NEXT_PUBLIC_SUB_ROUTE;

export const shortenAddress = (
  address: string,
  width: undefined | number = undefined,
  startingAddressLetters = 10,
  endingAddressLetters = 4,
) => {
  let startAddressLetters = startingAddressLetters;
  let endAddressLetters = endingAddressLetters;
  if (width) {
    if (width < 235) {
      startAddressLetters = 6;
      endAddressLetters = 3;
    }
  }

  if (address.length < startAddressLetters + endAddressLetters) {
    return address;
  }

  return `${address.substring(0, startAddressLetters)}...${address.substring(
    address.length - endAddressLetters,
  )}`;
};

export const copyToClipboard = (value: any) =>
  navigator.clipboard.writeText(value);

/**
 * @deprecated
 */
export const toFixedWithoutRounding = (value: string, decimals: number) =>
  (value.match(new RegExp(`^-?\\d+(?:.\\d{0,${decimals}})?`)) as string[])[0];

export const isNumber = (str: string) => {
  if (str.trim() === '') {
    return false;
  }
  return !isNaN(+str);
};

export const formatEtherNumber = (value: string | undefined, decimals = 4) => {
  if (!value) {
    return -1;
  }
  const number = toFixedWithoutRounding(
    formatEther(value.toString()),
    decimals,
  );

  return +number;
};

export const isFloat = (n: number) => {
  return Number(n) === n && n % 1 !== 0;
};

export const toStringPrecision = (num: number) => {
  let res = '';

  if (Math.abs(num) < 1.0) {
    const e = parseInt(num.toString().split('e-')[1]);
    if (e) {
      num *= Math.pow(10, e - 1);
      res = '0.' + new Array(e).join('0') + num.toString().substring(2);
    }
  } else {
    let e = parseInt(num.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      num /= Math.pow(10, e);
      res = num + new Array(e + 1).join('0');
    }
  }
  res = res.includes('.') ? Number(res).toFixed(8) : res;
  return res || num.toString();
};

export const validateEmail = (email: string) => {
  const emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test(email);
};

export const chainSubscribeKeyMapping: IIndexable = {
  BNB: 'BNB',
  ETH: 'Ethereum',
  SD: 'SD',
  POLYGON: 'Polygon',
  HEDERA: 'Hedera',
  FANTOM: 'Fantom',
  NEAR: 'Near',
  TERRA: 'Terra 2.0',
};

export const formatNumber = (value: number, isFloor = true) => {
  const updatedValue = isFloor ? Math.floor(value) : value;
  return updatedValue.toLocaleString('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  });
};

export async function fetchBalances(config: NetworkConfigType) {
  const account = getAccount();
  if (account.address) {
    try {
      const balances = await Promise.all([
        fetchBalance({
          address: account.address,
          token: config.contractAddresses.erc20,
          chainId: +config.chainId,
        }),
        fetchBalance({
          address: account.address,
          token: config.contractAddresses.xtoken.token,
          chainId: +config.chainId,
        }),
      ]);
      return {
        erc20Balance: +toFixedWithoutRounding(balances[0].formatted || '0', 4),
        xTokenBalance: +toFixedWithoutRounding(balances[1].formatted || '0', 4),
      };
    } catch (err) {
      return {
        erc20Balance: 0,
        xTokenBalance: 0,
      };
    }
  } else {
    return {
      erc20Balance: 0,
      xTokenBalance: 0,
    };
  }
}

export const getTokenForWebSDK = (token: Token) => {
  switch (token) {
    case Token.ETH:
      return TOKEN.ETHX;

    case Token.BNB:
      return TOKEN.BNBX;

    case Token.POLYGON:
      return TOKEN.MATICX;

    case Token.SD:
      return TOKEN.SD;
  }
  return TOKEN.ETHX;
};

export const emitEvent = (name: string, data?: any) => {
  const id = new Date().getTime();
  store.dispatch(
    updateEventData({
      emitter: {
        id,
        name,
        data,
      },
    }),
  );
};

export const getNativeChain = (token: string, chainId: number) => {
  if (token === POLYGON) {
    return chainId === 137;
  }
  return true;
};

export const getBaseUrl = () => {
  const subRoute = process.env.NEXT_PUBLIC_SUB_ROUTE || '';
  let baseUrl = `${subRoute ? subRoute + '/' : '/'}`;

  if (typeof document !== 'undefined') {
    const { pathname } = window.location;
    const ipfsMatch = /.*\/Qm\w{44}\//.exec(pathname);

    baseUrl = ipfsMatch ? ipfsMatch[0] : baseUrl;
  }
  return baseUrl;
};

export const getUrlForStaticImage = (src: any) => {
  const baseUrl = getBaseUrl();
  const baseUrlWithoutTrailingSlash = baseUrl.substring(0, baseUrl.length - 1);
  const trimmedSrc =
    isIPFS || !!SUB_ROUTE
      ? src.src.substring(2, (src as any).src.length)
      : src.src;

  return `${baseUrlWithoutTrailingSlash}${trimmedSrc}`;
};

export const getUrlForLocalImage = (src: string) => {
  const baseUrl = getBaseUrl();
  const baseUrlWithoutTrailingSlash = baseUrl.substring(0, baseUrl.length - 1);

  return `${baseUrlWithoutTrailingSlash}${src}`;
};
