import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Text, useToast } from '@chakra-ui/react';
import { TransactionResponse } from '@ethersproject/providers';
import {
  TOKENTYPE,
  useAllowanceMutation,
  useStakeMutation,
  useUnstakeMutation,
  useWithdrawalMutation,
} from '@stader-labs/web-sdk';
import { useQueryClient } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';
import { shallowEqual } from 'react-redux';
import { useAccount, useChains, useDisconnect } from 'wagmi';

import { ChainWithCustomData, WagmiConfig } from '@/config/wagmi.config';
import {
  TXN_STAGE_STEP,
  TXN_STAGE_TYPE,
} from '@/constants/stake-page.constant';
import { QK } from '@/factories/query-key.factory';
import { ErrorToast, SuccessCheck } from '@/icons';
import { useChainData } from '@/providers/ChainDataProvider';
import {
  clearStakeData,
  clearUserData,
  clearWalletData,
  resetTxnData,
  store,
  updateStakeData,
  updateTransactionStatus,
  updateTxnData,
  updateTxnHash,
  updateUserData,
  updateWalletData,
  useAppDispatch,
  useAppSelector,
} from '@/store';
import {
  resetTxnDataInStakePage,
  setActionData,
  setApprovalData,
  setTxnDetail,
} from '@/store/slices/stake-page.slice';
import { setLastTransaction } from '@/store/slices/userSlice';

import Icon from '../components/Icon';
import TransactionModal from '../components/TransactionModal';
import { VID } from '../constants/common';
import { DAYS_TO_WAIT_FOR_WITHDRAW, SD } from '../constants/constants';
import { APPROVAL_AMOUNT } from '../constants/contract';
import {
  AppTransactionType,
  EVENTS,
  TRANSACTION_TYPE,
} from '../constants/events';
import {
  MAX_STAKE,
  MAX_UNSTAKE,
  STAKE_CTA,
  STAKE_FAILED,
  STAKE_SUCCESSFUL,
  UNSTAKE_CTA,
  UNSTAKE_FAILED,
  UNSTAKE_SUCCESSFUL,
  WALLET_CONNECTED_SUCCESSFULLY,
  WITHDRAWAL_FAILED,
  WITHDRAWAL_SUCCESSFUL,
  WITHDRAW_CTA,
} from '../constants/firebase';
import { TXN_STATUSES, TXN_TYPES } from '../constants/transactions';
import { walletLabelMapping } from '../constants/walletMenuOptions';
// import Referral from "../components/Referral";
// import useReferral from "../hooks/useReferral";
import useAutoConnect from '../hooks/useAutoConnect';
import { useEmbed } from '../hooks/useEmbed';
import useQueryReferral from '../hooks/useQueryReferral';
import { emitEvent, getNativeChain } from '../utils/common';
import { trackEvent } from '../utils/firebase';
import { useEthersSigner } from './useEthersSigner';
import { isErrorRejectedByUser } from './utils';

const token = process.env.NEXT_PUBLIC_TOKEN || '';
const network = process.env.NEXT_PUBLIC_NETWORK || '0';

export interface TxnErrorType {
  hasUserDenied: boolean;
  promptError: string;
}

const initialTxnError = {
  hasUserDenied: false,
  promptError: '',
};

const Services = () => {
  const toast = useToast();
  const [cookies] = useCookies([VID]);
  const {
    isConnected,
    address,
    connector: activeConnector,
    chain: modifiedChain,
    chainId,
  } = useAccount<WagmiConfig>();

  const chain = modifiedChain as ChainWithCustomData | undefined;

  const selectedStakeToken = useAppSelector(
    (state) => state.stakePage.stakeToken,
  );
  const txnDetails = useAppSelector((state) => state.stakePage.txnDetails);
  const { contractConfig, switchChain } = useChainData();

  const chains = useChains<WagmiConfig>();

  const queryClient = useQueryClient();

  // * Mutations
  const { mutateAsync: approveToken } = useAllowanceMutation({
    stakeToken: selectedStakeToken?.symbol ?? undefined,
  });
  const { mutateAsync: stakeToken } = useStakeMutation({
    stakeToken: selectedStakeToken?.symbol ?? undefined,
  });
  const { mutateAsync: unstakeToken } = useUnstakeMutation();
  const { mutateAsync: withdrawToken } = useWithdrawalMutation();

  const stakeReferralId = useQueryReferral();

  const signer = useEthersSigner({ chainId });

  const { emitter } = useAppSelector((state) => state.event, shallowEqual);
  const { isNativeChain, txn } = useAppSelector(
    (state) => state.user,
    shallowEqual,
  );

  const { stakeAmount, unstakeAmount, txnLoader } = useAppSelector(
    (state) => state.stake,
    shallowEqual,
  );

  const isRetryTransaction = useAppSelector(
    (state) => state.stake.isRetryTransaction,
  );

  const dispatch = useAppDispatch();

  const { isEmbed, isSafeApp } = useEmbed();

  const { disconnect } = useDisconnect();

  const transactionRef = useRef<any>(null);

  const [txError, setTxError] = useState<TxnErrorType>(initialTxnError);
  const transactionWaitRef = useRef(false);

  const isValidChain = chains.find((chain) => chain.id === chainId);

  const lastTransaction = useAppSelector((state) => state.user.lastTransaction);

  useAutoConnect();

  useEffect(() => {
    dispatch(
      updateUserData({
        isEmbed,
        isSafeApp,
      }),
    );
  }, [dispatch, isEmbed, isSafeApp]);

  const iframeChain = useMemo(() => {
    if ((isEmbed || isSafeApp) && chainId) {
      return chainId;
    }
    return null;
  }, [isEmbed, isSafeApp, chainId]);

  useEffect(() => {
    if (iframeChain && chains.find((x) => x.id === iframeChain)) {
      setTimeout(() => {
        dispatch(
          updateUserData({
            isNativeChain: getNativeChain(token, iframeChain),
          }),
        );
      }, 2000);
    }
  }, [chains, dispatch, iframeChain]);

  useEffect(() => {
    if (activeConnector && isConnected && chainId) {
      dispatch(
        updateWalletData({
          walletAddress: address,
          walletName: activeConnector.name,
          connectorID: activeConnector.id,
          isConnected: isConnected,
          chainConfig: chain,
          // isWrongChain: checkWrongChain(activeConnector.chains, chain),
          switchNetwork: switchChain,
          activeConnector: activeConnector,
          isWrongChain: !isValidChain,
          walletModal: {
            isVisible: !isValidChain,
            error: null,
          },
        }),
      );
    }
  }, [
    activeConnector,
    address,
    isConnected,
    chainId,
    isNativeChain,
    isEmbed,
    dispatch,
    switchChain,
    isValidChain,
  ]);

  useEffect(() => {
    if (isConnected && activeConnector) {
      trackEvent(WALLET_CONNECTED_SUCCESSFULLY, {
        wallet_name: activeConnector
          ? walletLabelMapping[activeConnector?.id.toLowerCase()]
          : '',
        wallet_address: address,
      });
    }
  }, [isConnected, activeConnector]);

  const initiateNewTxn = useCallback(
    ({ isRetry = false } = {}) => {
      transactionWaitRef.current = false;
      setTxError(initialTxnError);
      dispatch(
        updateStakeData({
          txnLoader: true,
          isRetryTransaction: isRetry,
        }),
      );
      dispatch(resetTxnData());
    },
    [dispatch],
  );

  const resetTxns = useCallback(() => {
    dispatch(updateStakeData({ txnLoader: false, txnFeedback: false }));
  }, [dispatch]);

  const handleTxModalClose = useCallback(() => {
    dispatch(updateStakeData({ txnLoader: false }));

    dispatch(resetTxnDataInStakePage());
  }, [dispatch]);

  const updateTransactionDetails = useCallback(
    (
      txn: TransactionResponse,
      type: string,
      amount: string | null,
      token: string,
      id: number | null = null,
    ) => {
      dispatch(
        updateTxnData({
          txn: {
            original: txn,
            hash: txn.hash,
            status: TXN_STATUSES.PENDING,
            amount,
            token,
            type,
            id,
          },
        }),
      );
    },
    [dispatch],
  );

  const setTxnError = useCallback(
    (error: string, hasUserDenied = false, custmError?: string) => {
      setTxError({
        hasUserDenied: hasUserDenied,
        promptError: error,
      });
      if (!txnLoader) {
        let errorMsg = hasUserDenied ? 'User rejected request' : error;
        if (custmError) {
          errorMsg = custmError;
        }
        toast({
          description: (
            <Text fontWeight={600} fontSize="16px">
              {errorMsg}
            </Text>
          ),
          status: 'error',
          icon: <Icon Icon={ErrorToast} width="24px" height="24px" />,
          duration: 5000,
          position: 'top',
          isClosable: false,
        });
      }
    },
    [toast, txnLoader],
  );

  interface handleClaimProps {
    id: number;
    amount: string;
    oldContract?: boolean;
  }
  const handleClaim = async (
    { id, amount, oldContract = false }: handleClaimProps,
    { isRetry = false } = {},
  ) => {
    trackEvent(WITHDRAW_CTA, {
      withdraw_amount: amount,
      is_retry: isRetry,
    });

    initiateNewTxn({
      isRetry,
    });
    if (address && signer && contractConfig) {
      withdrawToken({
        address,
        signer,
        input: id,
        metaData: {
          topic: '',
          isNativeChain: true,
          connectorID: '',
        },
        oldContract,
      })
        .then((response: TransactionResponse | null) => {
          if (response != null) {
            updateTransactionDetails(
              response,
              TXN_TYPES.CLAIM,
              amount,
              contractConfig.xToken.symbol,
              id,
            );
          }
        })
        .catch((error: any) => {
          const errorMessage = isErrorRejectedByUser(error)
            ? 'Withdrawal denied'
            : error?.error || error?.message;

          trackEvent(WITHDRAWAL_FAILED, {
            reason: isErrorRejectedByUser(error)
              ? 'user rejected'
              : error?.error || error?.message,
            is_retry: isRetry,
          });
          setTxnError(
            error?.error || error?.message,
            isErrorRejectedByUser(error),
            errorMessage,
          );
        });
    }
  };

  const handleApproveToken = (errorMessages: any, { isRetry = false } = {}) => {
    initiateNewTxn({
      isRetry,
    });
    if (address && signer && selectedStakeToken) {
      dispatch(
        setTxnDetail({
          type: TXN_STAGE_TYPE.STAKE,
          step: TXN_STAGE_STEP.APPROVE,
        }),
      );
      dispatch(
        setApprovalData({
          error: null,
        }),
      );
      dispatch(updateStakeData({ approveTokenLoading: true }));

      // ? Request from ledger team, not to ask for infinity approval
      const amountToApprove = isEmbed ? stakeAmount : APPROVAL_AMOUNT;

      approveToken({
        address,
        signer,
        input: amountToApprove,
        tokenType: TOKENTYPE.TOKEN,
        metaData: {
          topic: '',
          isNativeChain: chain?.custom?.isNativeChain ?? false,
          connectorID: '',
        },
      })
        .then((result: TransactionResponse | null) => {
          if (result != null) {
            updateTransactionDetails(
              result,
              TXN_TYPES.ERC20_APPROVE,
              null,
              selectedStakeToken.symbol,
            );
            dispatch(
              setApprovalData({
                hash: result.hash,
              }),
            );
          }
        })
        .catch((error: any) => {
          const errorMessage = isErrorRejectedByUser(error)
            ? 'Token approval denied'
            : errorMessages?.['stakeDenied'] || token !== SD
              ? 'Staking denied'
              : 'Delegation denied';

          setTxnError(
            error?.error || error?.message,
            isErrorRejectedByUser(error),
            errorMessage,
          );

          dispatch(
            setApprovalData({
              error: isErrorRejectedByUser(error)
                ? errorMessage
                : error.message,
            }),
          );

          resetTxns();
        })
        .finally(() => {
          dispatch(updateStakeData({ approveTokenLoading: false }));
        });
    }
  };

  const handleApproveTokenX = async (
    errorMessages: any,
    { isRetry = false } = {},
  ) => {
    initiateNewTxn({
      isRetry,
    });
    if (address && signer && selectedStakeToken) {
      dispatch(updateStakeData({ approveTokenXLoading: true }));
      dispatch(
        setTxnDetail({
          type: TXN_STAGE_TYPE.UNSTAKE,
          step: TXN_STAGE_STEP.APPROVE,
        }),
      );
      dispatch(
        setApprovalData({
          error: null,
        }),
      );

      // ? Request from ledger team, not to ask for infinity approval
      const amountToApprove = isEmbed ? unstakeAmount : APPROVAL_AMOUNT;

      approveToken({
        address,
        signer,
        input: amountToApprove,
        tokenType: TOKENTYPE.TOKENX,
        metaData: {
          topic: '',
          isNativeChain: chain?.custom?.isNativeChain ?? false,
          connectorID: '',
        },
      })
        .then((result: TransactionResponse | null) => {
          if (result != null) {
            updateTransactionDetails(
              result,
              TXN_TYPES.XTOKEN_APPROVE,
              null,
              selectedStakeToken.symbol,
            );
            dispatch(
              setApprovalData({
                hash: result.hash,
              }),
            );
          }
        })
        .catch((error: any) => {
          const userReject = isErrorRejectedByUser(error);
          const errorMessage = userReject
            ? 'Token approval denied'
            : errorMessages['unstakeDenied'] || token !== SD
              ? 'Unstaking denied'
              : 'Withdraw denied';
          setTxnError(error.error || error.message, userReject, errorMessage);

          dispatch(
            setApprovalData({
              error: isErrorRejectedByUser(error)
                ? errorMessage
                : error.message,
            }),
          );

          resetTxns();
        })
        .finally(() => {
          dispatch(updateStakeData({ approveTokenXLoading: false }));
        });
    }
  };

  const handleStake = ({ isRetry = false } = {}) => {
    const { user, stake } = store.getState();
    trackEvent(STAKE_CTA, {
      stake_amount_entered: parseFloat(stake.stakeAmount),
      is_retry: isRetry,
    });
    if (+stakeAmount === user.tokenAmount) {
      trackEvent(MAX_STAKE, {
        stake_amount_entered: parseFloat(stakeAmount),
        is_retry: isRetry,
      });
    }
    initiateNewTxn({
      isRetry,
    });

    if (address && signer && selectedStakeToken) {
      dispatch(updateStakeData({ isStaking: true }));
      dispatch(
        setActionData({
          error: null,
        }),
      );
      dispatch(
        setTxnDetail({
          type: TXN_STAGE_TYPE.STAKE,
          step: TXN_STAGE_STEP.ACTION,
        }),
      );
      stakeToken({
        address,
        signer,
        input: stake.stakeAmount,
        metaData: {
          topic: '',
          isNativeChain: chain?.custom?.isNativeChain ?? false,
          connectorID: '',
        },
        referralId: stakeReferralId,
      })
        .then((result: TransactionResponse | null) => {
          if (result != null) {
            updateTransactionDetails(
              result,
              TXN_TYPES.STAKE,
              stakeAmount,
              selectedStakeToken.symbol,
            );

            dispatch(
              setActionData({
                hash: result.hash,
              }),
            );
          }

          dispatch(updateStakeData({ stakeAmount: '' }));
        })
        .catch((error: any) => {
          const errorMessage = isErrorRejectedByUser(error)
            ? token !== SD
              ? 'Staking denied'
              : 'Delegation denied'
            : error?.error || error?.message;

          trackEvent(STAKE_FAILED, {
            stake_amount_entered: parseFloat(stakeAmount),
            reason: isErrorRejectedByUser(error)
              ? 'user rejected'
              : error?.error || error?.message,
            is_retry: isRetry,
          });
          setTxnError(
            error?.error || error?.message,
            isErrorRejectedByUser(error),
            errorMessage,
          );

          dispatch(
            setActionData({
              error: isErrorRejectedByUser(error)
                ? token !== SD
                  ? 'Staking denied'
                  : 'Delegation denied'
                : error?.error || error?.message,
            }),
          );
        })
        .finally(() => {
          dispatch(updateStakeData({ isStaking: false }));
        });
    }
  };

  const handleUnStake = ({ isRetry = false } = {}) => {
    const { user, stake } = store.getState();
    let isMaxTransaction = false;
    trackEvent(UNSTAKE_CTA, {
      unstake_amount_entered: parseFloat(stake.unstakeAmount),
      is_retry: isRetry,
    });
    if (+unstakeAmount === +user.tokenXAmount) {
      trackEvent(MAX_UNSTAKE, {
        stake_amount_entered: parseFloat(stake.unstakeAmount),
        is_retry: isRetry,
      });
      isMaxTransaction = true;
    }
    initiateNewTxn({ isRetry });

    if (address && signer && selectedStakeToken) {
      dispatch(updateStakeData({ isUnstaking: true }));
      dispatch(
        setTxnDetail({
          type: TXN_STAGE_TYPE.UNSTAKE,
          step: TXN_STAGE_STEP.ACTION,
        }),
      );
      dispatch(
        setActionData({
          error: null,
        }),
      );
      unstakeToken({
        address,
        signer,
        input: stake.unstakeAmount,
        metaData: {
          topic: '',
          isNativeChain: chain?.custom?.isNativeChain ?? false,
          connectorID: '',
        },
        referralId: stakeReferralId,
        lstToken: undefined,
        isMaxTransaction,
      })
        .then((result: TransactionResponse | null) => {
          if (result != null) {
            updateTransactionDetails(
              result,
              TXN_TYPES.UNSTAKE,
              unstakeAmount,
              selectedStakeToken.symbol,
            );

            dispatch(
              setActionData({
                hash: result.hash,
              }),
            );
          }
          dispatch(updateStakeData({ unstakeAmount: '' }));
        })
        .catch((error: any) => {
          dispatch(updateStakeData({ isUnstaking: false }));
          trackEvent(UNSTAKE_FAILED, {
            unstake_amount_entered: parseFloat(unstakeAmount),
            reason: isErrorRejectedByUser(error)
              ? 'user rejected'
              : error?.error || error?.message,
            is_retry: isRetry,
          });
          setTxnError(
            error.error || error.message,
            isErrorRejectedByUser(error),
            isErrorRejectedByUser(error)
              ? token !== SD
                ? 'Unstaking denied'
                : 'Withdrawal denied'
              : error?.error || error?.message,
          );

          dispatch(
            setActionData({
              error: isErrorRejectedByUser(error)
                ? token !== SD
                  ? 'Unstaking denied'
                  : 'Withdrawal denied'
                : error?.error || error?.message,
            }),
          );
        })
        .finally(() => {
          dispatch(updateStakeData({ isUnstaking: false }));
        });
    }
  };

  const handleTransactions = (transaction: any, { isRetry = false } = {}) => {
    transactionRef.current = transaction;
    const { amount, withdrawalId } = (() => {
      // IIFE
      if (transaction.type === TRANSACTION_TYPE.STAKE) {
        return { amount: stakeAmount as string, withdrawalId: null };
      }

      if (transaction.type === TRANSACTION_TYPE.UNSTAKE) {
        return { amount: unstakeAmount as string, withdrawalId: null };
      }

      if (transaction.type === TRANSACTION_TYPE.WITHDRAW) {
        return {
          amount: transaction.amount as string,
          withdrawalId: transaction.id,
        };
      }

      return { amount: null, withdrawalId: null };
    })();

    dispatch(
      setLastTransaction({
        type: (transaction.type as AppTransactionType) ?? null,
        amount,
        withdrawalId,
      }),
    );

    switch (transaction.type) {
      case TRANSACTION_TYPE.STAKE:
        return handleStake({
          isRetry,
        });
      case TRANSACTION_TYPE.UNSTAKE:
        return handleUnStake({
          isRetry,
        });
      case TRANSACTION_TYPE.WITHDRAW:
        return handleClaim(transaction, {
          isRetry,
        });
      case TRANSACTION_TYPE.APPROVE_TOKEN:
        return handleApproveToken(transaction.errorMessages, {
          isRetry,
        });
      case TRANSACTION_TYPE.APPROVE_TOKEN_X:
        return handleApproveTokenX(transaction.errorMessages, {
          isRetry,
        });
    }
  };

  const attachEventListners = (event: any) => {
    switch (event.name) {
      // case EVENTS.HANDLE_CONNECT:
      //   if (event.data) {
      //     connectors.forEach((item: any) => {
      //       if (item.id === event.data.id) {
      //         return connect({ connector: item });
      //       }
      //     });
      //   }
      //   break;

      case EVENTS.HANDLE_SWITCH_NETWORK:
        if (event.data) {
          dispatch(
            updateWalletData({
              configChain: event.data,
            }),
          );
        }
        break;

      case EVENTS.HANDLE_DISCONNECT:
        dispatch(clearWalletData());
        dispatch(clearUserData());
        dispatch(clearStakeData());
        disconnect();
        break;

      case EVENTS.HANDLE_TRANSACTION:
        if (event.data) {
          handleTransactions(event.data);
        }
        break;

      case EVENTS.HANDLE_RETRY_TRANSACTION:
        if (event.data && lastTransaction.type != null) {
          if (lastTransaction.type === TRANSACTION_TYPE.STAKE) {
            dispatch(updateStakeData({ stakeAmount: lastTransaction.amount }));
          }

          if (lastTransaction.type === TRANSACTION_TYPE.UNSTAKE) {
            dispatch(
              updateStakeData({ unstakeAmount: lastTransaction.amount }),
            );
          }

          const transactionData =
            lastTransaction.type === TRANSACTION_TYPE.WITHDRAW
              ? {
                  type: lastTransaction.type,
                  id: lastTransaction.withdrawalId,
                }
              : {
                  type: lastTransaction.type,
                };

          handleTransactions(transactionData, {
            isRetry: true,
          });
        }
        break;
    }
  };

  useEffect(() => {
    attachEventListners(emitter);
  }, [emitter]);

  const getStakeUnstakeFirebaseMethodName = useCallback(
    (isSuccessful: boolean, type: string) => {
      switch (type) {
        case TXN_TYPES.UNSTAKE:
          return isSuccessful ? UNSTAKE_SUCCESSFUL : UNSTAKE_FAILED;
        case TXN_TYPES.STAKE:
          return isSuccessful ? STAKE_SUCCESSFUL : STAKE_FAILED;
        case TXN_TYPES.CLAIM:
          return isSuccessful ? WITHDRAWAL_SUCCESSFUL : WITHDRAWAL_FAILED;
      }
      return '';
    },
    [],
  );

  const logStakeUnstakeEvent = useCallback(
    (isSuccessful: boolean, type: string, hash = '') => {
      let primaryStakedValue = 'withdraw_amount';
      if (type !== TXN_TYPES.CLAIM) {
        primaryStakedValue = isSuccessful
          ? `token_${type === TXN_TYPES.UNSTAKE ? 'unstaked' : 'staked'}_now`
          : `${
              type === TXN_TYPES.UNSTAKE ? 'stake' : 'unstake'
            }_amount_entered`;
      }

      const amount = txn.amount;

      if (isSuccessful && token === SD) {
        queryClient.invalidateQueries({ queryKey: QK.user.sdBalance() });
      }

      trackEvent(getStakeUnstakeFirebaseMethodName(isSuccessful, type), {
        [primaryStakedValue]: parseFloat(Number(amount).toString()),
        hash: hash,
        is_retry: isRetryTransaction,
      });
    },
    [
      txn.amount,
      getStakeUnstakeFirebaseMethodName,
      isRetryTransaction,
      queryClient,
    ],
  );

  const handleTxViewOnEtherScan = () => {
    const url = `${chain?.blockExplorers?.default?.url}/tx/${txn.hash}`;
    window.open(url, '_blank');
  };

  const isApprovalTransaction = (transactionType: string) => {
    return [TXN_TYPES.ERC20_APPROVE, TXN_TYPES.XTOKEN_APPROVE].includes(
      transactionType,
    );
  };

  const updateTransactionDetailsWithResults = useCallback(
    (status: string) => {
      dispatch(updateTransactionStatus({ status }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      txn.original &&
      txn.status === TXN_STATUSES.PENDING &&
      !transactionWaitRef.current
    ) {
      transactionWaitRef.current = true;
      txn.original
        .wait(1)
        .then((confirmation: any) => {
          if (confirmation) {
            let hash: string;

            if (isSafeApp) {
              hash = confirmation.logs[0].transactionHash;
              dispatch(
                updateTxnHash({
                  hash,
                }),
              );
            } else {
              hash = txn.hash;
            }

            const type = txn.type;
            if (confirmation.blockNumber && confirmation.status) {
              updateTransactionDetailsWithResults(TXN_STATUSES.SUCCESS);
              if (!isApprovalTransaction(type)) {
                logStakeUnstakeEvent(true, type, hash);

                // ? Invalidate balanceOf query
                // TODO: move to web3-sdk, or is it too much of a hassle?
                const invalidateBalanceOfQueries = () => {
                  queryClient.invalidateQueries({
                    predicate: (query) =>
                      query.queryKey[0] === 'readContract' &&
                      (query.queryKey[1] as { functionName: string })
                        ?.functionName === 'balanceOf',
                  });
                };
                invalidateBalanceOfQueries();
              }

              if (isApprovalTransaction(type)) {
                resetTxns();

                toast({
                  description: (
                    <Text fontWeight={600} fontSize="16px">
                      Token approved
                    </Text>
                  ),
                  status: 'success',
                  icon: <Icon Icon={SuccessCheck} width="24px" height="24px" />,
                  duration: 5000,
                  position: 'top',
                  isClosable: false,
                });

                // ? Automatically proceed to stake/unstake
                if (txnDetails?.type === TXN_STAGE_TYPE.STAKE) {
                  emitEvent(EVENTS.HANDLE_TRANSACTION, {
                    type: TRANSACTION_TYPE.STAKE,
                    amount: stakeAmount,
                  });
                }

                if (txnDetails?.type === TXN_STAGE_TYPE.UNSTAKE) {
                  emitEvent(EVENTS.HANDLE_TRANSACTION, {
                    type: TRANSACTION_TYPE.UNSTAKE,
                    amount: unstakeAmount,
                  });
                }
              }
              if (!txnLoader && !isApprovalTransaction(type)) {
                toast({
                  description: `${type} Transaction Successful`,
                  status: 'success',
                  duration: 5000,
                  position: 'top',
                });
              }
            } else {
              logStakeUnstakeEvent(false, type, hash);
              updateTransactionDetailsWithResults(TXN_STATUSES.ERROR);
              if (!txnLoader && !isApprovalTransaction(type)) {
                toast({
                  description: `${type} Transaction failed`,
                  status: 'error',
                  duration: 5000,
                  position: 'top',
                });
              }
            }
          }
        })
        .catch((error: any) => {
          setTxnError(error?.error || error?.message);
        });
    }
  }, [
    cookies,
    dispatch,
    isSafeApp,
    logStakeUnstakeEvent,
    resetTxns,
    setTxnError,
    toast,
    txn.hash,
    txn.original,
    txn.status,
    txn.type,
    txnLoader,
    updateTransactionDetailsWithResults,
  ]);

  return (
    <>
      {/* TODO: fix reerral */}
      {/* {isConnected &&
        referralId &&
        userType &&
        userType !== ReferralUserType.KOL && (
          <Referral
            user={userType}
            referralId={referralId as string}
            isValidReferralId={isValidReferralId}
            referralCallBack={removeReferralQueryParam}
            network={network}
            token={token}
            config={config}
          />
        )} */}
      {(txnDetails != null || txnLoader) && (
        <TransactionModal
          // ? `txnStage != null` is already true here
          isOpen={true}
          hash={txn.hash}
          isTxnProcessing={txn.status === TXN_STATUSES.PENDING}
          error={txError.promptError}
          daysToWaitForWithdraw={DAYS_TO_WAIT_FOR_WITHDRAW}
          hasUserDenied={txError.hasUserDenied}
          closeAlert={handleTxModalClose}
          handleTxView={handleTxViewOnEtherScan}
          transactionType={txn.type}
          token={token}
          network={network}
          chainId={chainId ?? 0}
          isSafeApp={isSafeApp}
        />
      )}
    </>
  );
};

export default Services;
