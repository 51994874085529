const QueryKeyFactory = {
  cms: {
    whatsNew: () => ['cms', 'whatsNew'],
  },
  user: {
    sdBalance: () => ['user', 'sdBalance'],
    sdBalanceFor: (address: string | null) => [
      'user',
      'sdBalance',
      { address },
    ],
  },
} as const;

export { QueryKeyFactory, QueryKeyFactory as QK };
