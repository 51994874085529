import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ValueOf } from 'type-fest';

import { ContractTokenConfig } from '@/config/contracts.config';
import {
  TXN_STAGE_APPROVAL_STATUS,
  TXN_STAGE_STEP,
  TXN_STAGE_TYPE,
} from '@/constants/stake-page.constant';

export interface StakePageState {
  stakeToken: ContractTokenConfig | null;
  txnDetails: {
    type: ValueOf<typeof TXN_STAGE_TYPE>;
    step: ValueOf<typeof TXN_STAGE_STEP>;
  } | null;
  approvalData: {
    status: ValueOf<typeof TXN_STAGE_APPROVAL_STATUS> | null;
    error: string | null;
    hash: string | null;
  };
  actionData: {
    error: string | null;
    hash: string | null;
  };
}

const initialState = {
  stakeToken: null,
  txnDetails: null,
  approvalData: {
    status: null,
    hash: null,
    error: null,
  },
  actionData: {
    error: null,
    hash: null,
  },
} satisfies StakePageState as StakePageState;

export const stakePageSlice = createSlice({
  name: 'web3',
  initialState,
  reducers: {
    setStakeToken: (
      state,
      action: PayloadAction<ContractTokenConfig | null>,
    ) => {
      state.stakeToken = action.payload;
    },

    setTxnDetail: (
      state,
      action: PayloadAction<StakePageState['txnDetails']>,
    ) => {
      state.txnDetails = action.payload;
    },

    setApprovalData: (
      state,
      action: PayloadAction<Partial<StakePageState['approvalData']>>,
    ) => {
      if (action.payload.hash !== undefined) {
        state.approvalData.hash = action.payload.hash;
      }

      if (action.payload.status !== undefined) {
        state.approvalData.status = action.payload.status;
      }

      if (action.payload.error !== undefined) {
        state.approvalData.error = action.payload.error;
      }
    },

    setActionData: (
      state,
      action: PayloadAction<Partial<StakePageState['actionData']>>,
    ) => {
      if (action.payload.hash !== undefined) {
        state.actionData.hash = action.payload.hash;
      }

      if (action.payload.error !== undefined) {
        state.actionData.error = action.payload.error;
      }
    },

    resetTxnDataInStakePage: (state) => {
      state.txnDetails = null;
      state.approvalData = {
        status: null,
        hash: null,
        error: null,
      };
      state.actionData = {
        error: null,
        hash: null,
      };
    },
  },
});

export const {
  setStakeToken,
  setTxnDetail,
  setApprovalData,
  setActionData,
  resetTxnDataInStakePage,
} = stakePageSlice.actions;

export const stakePageReducer = stakePageSlice.reducer;
