import { useEffect, useState } from 'react';

import { captureException } from '@sentry/nextjs';
import { usePathname } from 'next/navigation';
import { useSelector } from 'react-redux';

import {
  SENTRY_TAGS,
  SENTRY_TAGS_TYPE_VALUES,
} from '@/constants/monitoring.constants';
import { getCurrentChain } from '@/utils/common';

// Constants
import { BNB } from '../constants/constants';

type DomainDetails = {
  domain: string;
  isSpaceId: boolean;
};

const useDomainResolution = () => {
  const { walletAddress: address } = useSelector((state: any) => state.wallet);
  const pathname = usePathname();

  const token = getCurrentChain(pathname);
  const [domainData, setDomainData] = useState<DomainDetails>();

  useEffect(() => {
    if (address) {
      (async function fetchDomainName() {
        try {
          if (token === BNB) {
            const spaceIdResponse = await fetch(
              `https://open-platform.nodereal.io/8438d9c512534d20a2367b930e8863e3/spaceid/domain/names/byBinds`,
              {
                method: 'POST',
                body: JSON.stringify([address]),
              },
            );
            const spaceIdJson = await spaceIdResponse.json();

            if (spaceIdJson[address] && spaceIdJson[address].length > 0) {
              setDomainData({
                domain: spaceIdJson[address][0] + '.bnb',
                isSpaceId: true,
              });
              return;
            }
          }

          const udResponse = await fetch(
            `https://resolve.unstoppabledomains.com/reverse/${address.toLowerCase()}`,
            {
              method: 'GET',
              headers: {
                Authorization: 'Bearer f8ae74dd-e512-4ef8-aeae-ee2ebe637433',
              },
            },
          );
          const json = await udResponse.json();

          if (json.meta?.domain) {
            setDomainData({
              domain: json.meta.domain,
              isSpaceId: false,
            });
          }
        } catch (error) {
          captureException(error, {
            tags: {
              [SENTRY_TAGS.TYPE]: SENTRY_TAGS_TYPE_VALUES.WEB3,
              [SENTRY_TAGS.OPERATION]: 'fetchDomainName',
              [SENTRY_TAGS.MODULE]: 'dapp-sdk-v2',
            },
          });
        }
      })();
    }
  }, [address]);

  return domainData;
};

export default useDomainResolution;
