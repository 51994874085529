import React, { useEffect, useMemo } from 'react';

import { RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit';
import { captureException } from '@sentry/nextjs';
import { AppProvider } from '@stader-labs/web-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { usePathname } from 'next/navigation';
import { WagmiProvider } from 'wagmi';

import TermsAndCondition from '@/components/TermsAndCondition';
import { wagmiConfig as getWagmiConfig } from '@/config/wagmi.config';
import {
  SENTRY_TAGS,
  SENTRY_TAGS_TYPE_VALUES,
} from '@/constants/monitoring.constants';
import { useSetNetworkFromQP } from '@/hooks/useSetNetworkFromQP';
import { ChainDataProvider, useChainData } from '@/providers/ChainDataProvider';
import { updateStakeData, updateWalletData, useAppDispatch } from '@/store';
import { getCurrentChain } from '@/utils/common';

import { getConfig } from '../config';
import { useFirebaseEvent } from '../hooks/useFirebaseEvent';
import { Token } from '../types/common';
import { getTokenForWebSDK } from '../utils/common';
import Services from './Services';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
    mutations: {
      onError: (error, variables, context) => {
        captureException(error, {
          extra: {
            variables,
            context,
          },
          tags: {
            [SENTRY_TAGS.TYPE]: SENTRY_TAGS_TYPE_VALUES.REACT_QUERY,
          },
        });
      },
    },
  },
});

export const SdkAppClientProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const { chainId } = useChainData();
  const pathname = usePathname();
  const token = getCurrentChain(pathname);

  useSetNetworkFromQP();
  useFirebaseEvent();

  return (
    <AppProvider
      token={getTokenForWebSDK(token as unknown as Token)}
      chainId={chainId}
    >
      {children}
      <Services />
    </AppProvider>
  );
};

export const WagmiClientProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const dispatch = useAppDispatch();

  const defaultTheme = lightTheme({
    accentColor: '#3F6870',
    fontStack: 'system',
    overlayBlur: 'small',
  });
  const pathname = usePathname();

  const token = getCurrentChain(pathname);

  const wagmiConfig = useMemo(() => getWagmiConfig(token), [token]);

  const config = getConfig(token);

  useEffect(() => {
    dispatch(
      updateStakeData({
        blockExplorerURL: config.blockExplorerUrls[0],
      }),
    );
    dispatch(
      updateWalletData({
        config: config,
      }),
    );
  }, [config, dispatch]);

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          appInfo={{
            appName: 'Stader Labs',
            disclaimer: TermsAndCondition,
          }}
          theme={{
            ...defaultTheme,
            colors: {
              ...defaultTheme.colors,
              modalBackground: '#F5F5F7',
              modalText: '#3F6870',
            },
          }}
        >
          <ChainDataProvider>
            <SdkAppClientProvider>{children}</SdkAppClientProvider>
          </ChainDataProvider>
        </RainbowKitProvider>
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition="bottom-left"
          />
        )}
      </QueryClientProvider>
    </WagmiProvider>
  );
};
