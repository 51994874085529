import { FC } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { usePathname } from 'next/navigation';
import { RudderStackScript } from 'src/app-head';

import AnalyticsMapping from '@/constants/analytics_mapping.json';
import { descriptionMappings, titleMappings } from '@/constants/meta';
import { DappSDKProvider } from '@/dapp-sdk-v2';
import { getCustomTheme } from '@/themes';
import { semanticTokens, themeColors } from '@/themes';
import { getBaseUrl, getCurrentChain } from '@/utils/common';

import '@/styles/globals.scss';
import '@rainbow-me/rainbowkit/styles.css';

const DefaultHead = () => {
  const pathname = usePathname();

  const token = getCurrentChain(pathname);
  return (
    <Head>
      <title>{titleMappings[token]}</title>
      <meta name="description" content={descriptionMappings[token]} />
      <link rel="icon" href="./favicon.ico" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="./favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="./favicon-16x16.png"
      />
      <meta name="robots" content="index, follow" />
      <link rel="manifest" href="./manifest.json" />
      <link rel="apple-touch-icon" href="./apple-touch-icon.png" />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-Light.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-Regular.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-Medium.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-SemiBold.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-Bold.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-Heavy.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />

      <style
        dangerouslySetInnerHTML={{
          __html: `
            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-Light.woff") format("woff");
              font-weight: 300;
              font-style: normal;
            }

            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-Regular.woff") format("woff");
              font-weight: 400;
              font-style: normal;
            }

            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-Medium.woff") format("woff");
              font-weight: 500;
              font-style: normal;
            }

            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-SemiBold.woff") format("woff");
              font-weight: 600;
              font-style: normal;
            }

            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-Bold.woff") format("woff");
              font-weight: 700;
              font-style: normal;
            }

            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-Heavy.woff") format("woff");
              font-weight: 800;
              font-style: italic;
            }
          `,
        }}
      />

      <RudderStackScript
        basePath={getBaseUrl()}
        fileName={AnalyticsMapping['fileName']}
      />
    </Head>
  );
};

interface StaderAppProps extends AppProps {
  Component: AppProps['Component'] & {
    isHomePage?: boolean;
    isNodePage?: boolean;
  };
}

const App: FC<StaderAppProps> = ({ Component, pageProps }) => {
  return (
    <>
      <DefaultHead />
      <ChakraProvider theme={getCustomTheme(themeColors, semanticTokens)}>
        <DappSDKProvider>
          <Component {...pageProps} />
        </DappSDKProvider>
      </ChakraProvider>
    </>
  );
};

export default App;
