import {
  bsc,
  bscTestnet,
  goerli,
  holesky,
  mainnet,
  polygon,
  polygonAmoy,
  sepolia,
  xLayer,
  xLayerTestnet,
} from 'viem/chains';

import { BNB, ETH, POLYGON, SD } from './constants';

interface StakePageContent {
  title: string;
  subtitle: string;
  token: string;
  xToken: string;
  addXSymbolCTA: string;
  approveCTA: string;
  approveXCTA: string;
  stakeCTA: string;
  unstakeCTA: string;
  stakeInputLabel: string;
  unstakeInputLabel: string;
}

const ETHEREUM_CONTENT: StakePageContent = {
  title: 'Stake Ethereum',
  subtitle:
    'Deposit ETH and use ETHx in DeFi to earn staking rewards while exploring additional reward opportunities.',
  token: 'ETH',
  xToken: 'ETHx',
  addXSymbolCTA: 'Add ETHx',
  approveCTA: 'Approve ETH',
  approveXCTA: 'Approve ETHx',
  stakeCTA: 'Stake ETH',
  unstakeCTA: 'Unstake ETH',
  stakeInputLabel: 'Enter ETH amount',
  unstakeInputLabel: 'Enter ETHx amount',
};

const X_LAYER_CONTENT: StakePageContent = {
  title: 'Stake Ethereum',
  subtitle:
    'Deposit WETH and use WETHx in DeFi to earn staking rewards while exploring additional reward opportunities.',
  token: 'WETH',
  xToken: 'WETHx',
  addXSymbolCTA: 'Add WETHx',
  approveCTA: 'Approve WETH',
  approveXCTA: 'Approve WETHx',
  stakeCTA: 'Stake WETH',
  unstakeCTA: 'Unstake WETH',
  stakeInputLabel: 'Enter WETH amount',
  unstakeInputLabel: 'Enter WETHx amount',
};

const BNB_CONTENT: StakePageContent = {
  title: 'Stake BNB',
  subtitle: 'Stake BNB & use BNBx in DeFi while earning staking rewards',
  token: 'BNB',
  xToken: 'BNBx',
  addXSymbolCTA: 'Add BNBx',
  approveCTA: 'Approve Staking',
  approveXCTA: 'Approve BNBx',
  stakeCTA: 'Stake BNB',
  unstakeCTA: 'Unstake BNBx',
  stakeInputLabel: 'Enter BNB amount',
  unstakeInputLabel: 'Enter BNBx amount',
};

const POLYGON_CONTENT_MATIC: StakePageContent = {
  title: 'Stake Polygon',
  subtitle: 'Stake POL or Matic, and use MaticX while earning staking rewards',
  token: 'Matic',
  xToken: 'MaticX',
  addXSymbolCTA: 'Add MaticX',
  approveCTA: 'Approve MATIC',
  approveXCTA: 'Approve MaticX',
  stakeCTA: 'Stake MATIC',
  unstakeCTA: 'Unstake MATIC',
  stakeInputLabel: 'Enter Matic amount',
  unstakeInputLabel: 'Enter MaticX amount',
};

const POLYGON_CONTENT_POL: StakePageContent = {
  title: 'Stake Polygon',
  subtitle: 'Stake POL or Matic, and use MaticX while earning staking rewards',
  token: 'POL',
  xToken: 'MaticX',
  addXSymbolCTA: 'Add MaticX',
  approveCTA: 'Approve POL',
  approveXCTA: 'Approve MaticX',
  stakeCTA: 'Stake POL',
  unstakeCTA: 'Unstake POL',
  stakeInputLabel: 'Enter POL amount',
  unstakeInputLabel: 'Enter MaticX amount',
};

const SD_CONTENT: StakePageContent = {
  title: 'SD Utility Pool',
  subtitle:
    'Delegate SD to the SD Utility Pool and receive rewards for fueling Ethereum decentralization while supporting ETHx node operators.',
  token: 'SD',
  xToken: '',
  addXSymbolCTA: '',
  approveCTA: 'Approve SD Delegation',
  approveXCTA: 'Approve SD Withdrawal',
  stakeCTA: 'Delegate SD',
  unstakeCTA: 'Withdraw SD',
  stakeInputLabel: 'Enter SD amount',
  unstakeInputLabel: 'Enter SD amount',
};

export const STAKE_PAGE_CONTENT: Record<
  string,
  { chainIds: number[]; content: StakePageContent }[]
> = {
  [ETH]: [
    {
      chainIds: [mainnet.id, holesky.id],
      content: ETHEREUM_CONTENT,
    },
    {
      chainIds: [xLayer.id, xLayerTestnet.id],
      content: X_LAYER_CONTENT,
    },
  ],
  [BNB]: [{ chainIds: [bsc.id, bscTestnet.id], content: BNB_CONTENT }],
  [POLYGON]: [
    {
      chainIds: [polygon.id, polygonAmoy.id],
      content: POLYGON_CONTENT_POL,
    },
  ],
  [`${POLYGON}_MATIC`]: [
    {
      chainIds: [mainnet.id, sepolia.id],
      content: POLYGON_CONTENT_MATIC,
    },
  ],
  [`${POLYGON}_POL`]: [
    {
      chainIds: [mainnet.id, sepolia.id],
      content: POLYGON_CONTENT_POL,
    },
  ],
  [SD]: [
    {
      chainIds: [mainnet.id, goerli.id],
      content: SD_CONTENT,
    },
  ],
};

export const MAX_AMOUNT_THRESHOLD = {
  ETH: 0,
  BNB: 0,
  POLYGON: 0.1,
  SD: 0,
} as const;

export const TXN_STAGE_STEP = {
  APPROVE: 'APPROVE',
  ACTION: 'ACTION',
} as const;

export const TXN_STAGE_TYPE = {
  STAKE: 'STAKE',
  UNSTAKE: 'UNSTAKE',
  CLAIM: 'CLAIM',
} as const;

export const TXN_STAGE_APPROVAL_STATUS = {
  NATIVE: 'NATIVE',
  ALREADY_APPROVED: 'ALREADY_APPROVED',
  APPROVAL_REQUIRED: 'APPROVAL_REQUIRED',
} as const;
