import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Severity, captureException, captureMessage } from '@sentry/nextjs';
import { Hash } from 'viem';
import { useAccount, useChains, useSwitchChain } from 'wagmi';

import { CONTRACTS_CONFIG } from '@/config/contracts.config';
import { ChainWithCustomData, WagmiConfig } from '@/config/wagmi.config';
import { SWITCH_NETWORK } from '@/constants/analytics';
import {
  SENTRY_TAGS,
  SENTRY_TAGS_TYPE_VALUES,
} from '@/constants/monitoring.constants';
import { trackEvent } from '@/dapp-sdk-v2';
import { useAppDispatch } from '@/store';
import { setStakeToken } from '@/store/slices/stake-page.slice';

/**
 * @module useChainDataHook
 * @description
 * This hook provides chain-related data but **must remain a single instance** across the app.
 * It should **never** be used directly in components to prevent multiple instances,
 * which can cause inconsistent state and unnecessary re-renders.
 *
 * Instead, use the `useChainData` hook provided by `ChainDataProvider` to ensure a
 * single shared state across all components.
 *
 * @private
 * @returns Chain data including `chainId` and other network details.
 *
 * @example
 * // ❌ Incorrect usage (DO NOT USE)
 * const chainData = useChainDataHook(); // ❌ Creates multiple instances, causing issues
 *
 * // ✅ Correct usage
 * import { useChainData } from '@/providers/ChainDataProvider';
 * const chainData = useChainData(); // ✅ Ensures a single instance
 */
const useChainData = () => {
  const chains = useChains<WagmiConfig>();
  const { chainId = chains[0].id, address } = useAccount<WagmiConfig>();

  const prevChainIdRef = useRef<number | null>(null);

  const { switchChainAsync } = useSwitchChain();

  const dispatch = useAppDispatch();

  const isValidChain = useMemo(() => {
    return chains.some((chain) => chain.id === chainId);
  }, [chainId, chains]);

  const getContractConfigFor = useCallback(
    (chainId: number) => {
      if (!chainId) {
        return null;
      }

      if (chains.find((chain) => chain.id === chainId)) {
        return CONTRACTS_CONFIG[chainId];
      }

      return null;
    },
    [chains],
  );

  const contractConfig = useMemo(
    () => getContractConfigFor(chainId),
    [chainId, getContractConfigFor],
  );

  useEffect(() => {
    if (prevChainIdRef.current !== chainId) {
      const _contractConfig = getContractConfigFor(chainId);

      if (_contractConfig != null) {
        dispatch(setStakeToken(_contractConfig.stakeTokens[0]));
      } else {
        dispatch(setStakeToken(null));
      }

      prevChainIdRef.current = chainId;
    }

    // ? Run only on chain id change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  const chain = useMemo(() => {
    const _chain = chains.find((chain) => chain.id === chainId) as
      | ChainWithCustomData
      | undefined;

    return _chain ?? null;
  }, [chainId, chains]);

  const handleSwitch = useCallback(
    (
      newChainId: number = chains[0].id,
      { source = 'network-switcher' } = {},
      callback?: () => void,
    ) => {
      trackEvent(SWITCH_NETWORK, {
        previous_chain_id: chainId,
        new_chain_id: newChainId,
        chain_name: chains.find((chain) => chain.id === newChainId)?.name,
        source_of_switch: source,
      });

      switchChainAsync({ chainId: newChainId })
        .then(() => {
          callback && callback();
        })
        .catch((err) => {
          captureException(err, {
            user: {
              id: address,
            },
            tags: {
              [SENTRY_TAGS.TYPE]: SENTRY_TAGS_TYPE_VALUES.WEB3,
              [SENTRY_TAGS.OPERATION]: 'handleSwitch',
              [SENTRY_TAGS.MODULE]: 'wagmi',
            },
          });
        });
    },
    [address, chainId, chains, switchChainAsync],
  );

  const isWithdrawSupported = contractConfig?.isWithdrawSupported ?? false;

  const withdrawSupportedChains = useMemo(
    () =>
      Object.entries(CONTRACTS_CONFIG)
        .filter(([_, config]) => config.isWithdrawSupported)
        .map(([chainId]) => parseInt(chainId)),
    [],
  );

  const withdrawSupportedChainData = useMemo(
    () =>
      withdrawSupportedChains &&
      chains.filter((chain) => withdrawSupportedChains.includes(chain.id)),
    [chains, withdrawSupportedChains],
  );

  const handleStakeTokenSelect = (contractAddress: Hash) => {
    if (contractConfig == null) {
      captureMessage(
        'BAD REQUEST: This function should not be called without contractConfig',
        {
          user: {
            id: address,
          },
          level: Severity.Error,
          tags: {
            [SENTRY_TAGS.TYPE]: SENTRY_TAGS_TYPE_VALUES.WEB3,
            [SENTRY_TAGS.OPERATION]: 'handleStakeTokenSelect',
            [SENTRY_TAGS.MODULE]: 'wagmi',
          },
        },
      );
      return void 0;
    }

    const token = contractConfig.stakeTokens.find(
      (stakeToken) => stakeToken.address === contractAddress,
    );

    if (token == null) {
      captureMessage('No tokens found with this contract address', {
        user: {
          id: address,
        },
        level: Severity.Error,
        tags: {
          [SENTRY_TAGS.TYPE]: SENTRY_TAGS_TYPE_VALUES.WEB3,
          [SENTRY_TAGS.OPERATION]: 'handleStakeTokenSelect',
          [SENTRY_TAGS.MODULE]: 'wagmi',
        },
      });
      return void 0;
    }

    dispatch(setStakeToken(token));
  };

  return {
    chainId,
    chain,
    switchChain: handleSwitch,
    handleStakeTokenSelect,
    isValidChain,
    contractConfig,
    isWithdrawSupported,
    withdrawSupportedChains,
    withdrawSupportedChainData,
  };
};

export { useChainData as useChainDataHook };
